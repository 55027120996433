import { Locale } from 'Locale';

export enum DynamicContent {
	people = 'people',
}

export interface Content {
	readonly body: DynamicContent | { html: string; markup: string };
	readonly title: string;
}

export interface InfoPart {
	readonly content: { [key in Locale]: Content };
	readonly id: string;
	readonly orderIndex: number;
}
