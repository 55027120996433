import Firebase from 'firebase/app';
import * as React from 'react';
import { FirebasePost } from './FirebasePost';
import { mapPost } from './mapPost';
import { AuthenticationContext } from 'Authentication';
import { useQuery } from 'Firebase';
import { Locale } from 'Locale';
import { isPublished, Post } from 'Post';

export const useCategoryPosts = (category: string | null, locale: Locale): [Post[] | null, () => Promise<void>] => {
	const authStatus = React.useContext(AuthenticationContext);
	const filter = React.useCallback(
		(query: Firebase.firestore.Query) => {
			let modifiedQuery = (category !== null ? query.where(`categorySlugs.${locale}`, 'array-contains', category) : query);
			if (authStatus === null || !authStatus.isAdmin) {
				modifiedQuery = modifiedQuery.where(`content.${locale}.published`, '==', true);
			}
			return modifiedQuery.orderBy('orderIndex', 'desc');
		},
		[category, locale, authStatus],
	);

	const [posts, fetch] = useQuery<FirebasePost>('posts', filter);
	const filteredPosts = React.useMemo(() => {
		if (posts === null) {
			return posts;
		}
		if (authStatus?.isAdmin ?? false) {
			return posts.map(mapPost);
		}
		const now = new Date();
		return posts.map(mapPost).filter((post) => isPublished(post, locale, now));
	}, [posts]);

	return [filteredPosts, fetch];
};
