import Firebase from 'firebase/app';
import * as React from 'react';
import { useBodyClass } from '../Document';
import { runTransaction, useQuery } from '../Firebase';
import { Locale } from './Locale';
import { Person } from './Person';
import { PersonCell } from './PersonCell';

export const PeopleList: React.FunctionComponent<{
	locale: Locale;
	people: Person[];
	onEdit(person: Person): void;
	onUpdatePeople(people: Person[]): void;
}> = ({ locale, people, onEdit, onUpdatePeople }) => {
	const [draggedPerson, setDraggedPerson] = React.useState<Person | null>(null);
	const [targetPerson, setTargetPerson] = React.useState<Person | null>(null);

	useBodyClass(draggedPerson !== null ? 'is-dragging' : '');

	const movePerson = () => {
		if (draggedPerson === null || targetPerson === null) {
			return;
		}

		const draggedPostNewIndex = targetPerson.orderIndex + 1;
		const updates = [{...draggedPerson, orderIndex: draggedPostNewIndex}];

		const conflictingPostIndex = people.findIndex((person) => person.orderIndex === draggedPostNewIndex);
		let index = draggedPostNewIndex;
		for (let i = conflictingPostIndex; i >= 0; i -= 1) {
			index += 100;
			updates.push({...people[i], orderIndex: index});
		}

		onUpdatePeople(updates);
	};

	return <ul>
		{people.map((person) => <li
			draggable
			className={`${person === targetPerson ? 'drag-target' : ''} ${person.internal ? ' internal-person' : ''}`}
			key={person.id}
			onDragStart={() => { setDraggedPerson(person); }}
			onDragEnd={() => {
				setDraggedPerson(null);
				setTargetPerson(null);
			}}
			onDragOver={(event) => { event.preventDefault(); }}
			onDragEnter={() => {
				if (draggedPerson !== null) {
					setTargetPerson(draggedPerson === person ? null : person);
				}
			}}
			onDrop={(event) => {
				event.preventDefault();
				movePerson();
			}}
		>
			<PersonCell person={person} locale={locale} />
			<button onClick={() => { onEdit(person); }}>edit</button>
		</li>)}
	</ul>;
};

PeopleList.displayName = 'Person.PeopleList';

export const AdminPeopleList: React.FunctionComponent<{locale: Locale; onEdit(person: Person): void}> = ({ locale, onEdit }) => {
	const filter = React.useCallback((query: Firebase.firestore.Query) => query.orderBy('orderIndex', 'desc'), []);
	const [people] = useQuery<Person>('people', filter);

	const [updatedPeople, setUpdatedPeople] = React.useState<{[id: string]: Person}>({});

	const currentPeople = React.useMemo(() => {
		if (people === null || Object.values(updatedPeople).length === 0) {
			return people;
		}
		const currentPosts = people.map((post) => post.id in updatedPeople ? updatedPeople[post.id] : post);
		currentPosts.sort((a, b) => b.orderIndex - a.orderIndex);
		return currentPosts;
	}, [people, updatedPeople]);

	const updatePeople = async (people: Person[]) => {
		const newUpdatedPeople = {...updatedPeople};
		people.forEach((person) => { newUpdatedPeople[person.id] = person; });
		setUpdatedPeople(newUpdatedPeople);

		await runTransaction((firestore, transaction) => Promise.all(
			Object.values(people)
				.map((person) => transaction.update(firestore.collection('people').doc(person.id), person),
		)));
	};

	return currentPeople !== null ? <PeopleList
		locale={locale}
		people={currentPeople}
		onEdit={onEdit}
		onUpdatePeople={updatePeople}
	/> : null;
};

AdminPeopleList.displayName = 'Person.AdminPeopleList';
