import Firebase from 'firebase/app';
import * as React from 'react';
import { Link } from 'wouter';
import { useDoc, useQuery } from '../Firebase';
import { FirebasePost, mapPost } from '../FirebasePost';
import { Locale } from 'Locale';
import { Person } from 'Person/Person';
import { isPublished, Post } from 'Post';

export const PersonDetail: React.FunctionComponent<{
	locale: Locale;
	person: Person;
	posts: Post[];
	onClose(): void;
}> = ({ locale, person, posts, onClose }) => {
	const elementRef = React.useRef<HTMLDivElement | null>(null);
	React.useLayoutEffect(() => { elementRef.current?.scrollIntoView({behavior: 'smooth', block: 'nearest'}); }, []);

	return <div className='person-detail' ref={elementRef}>
		<h3>{person.firstName} {person.lastName}</h3>
		<p dangerouslySetInnerHTML={{__html: person.content[locale].description.html}} />
		<ul>
			{posts.map((post) => <li key={post.id}><Link to={`/${locale}/p/${post.content[locale].slug}`}>{post.content[locale].title}</Link></li>)}
		</ul>
		<button onClick={onClose}>
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#ffffff'>
				<path d='M3,5.28,4.84,3.44,16,14.57,27.16,3.44l1.76,1.76L17.76,16.37,29,27.52l-1.8,1.84L15.93,18.17,4.8,29.36,3,27.6,14.13,16.4Z'></path>
			</svg>
		</button>
	</div>;
};

export const FirebasePersonDetail: React.FunctionComponent<{
	locale: Locale;
	personId: string;
	onClose(): void;
}> = ({ locale, personId, onClose }) => {
	const person = useDoc<Person>('people', personId);

	const postsFilter = React.useCallback(
		(query: Firebase.firestore.Query) => query.where('peopleIds', 'array-contains', personId)
			.where(`content.${locale}.published`, '==', true)
			.orderBy(`content.${locale}.title`, 'asc'),
		[personId, locale],
	);
	const [posts] = useQuery<FirebasePost>('posts', postsFilter);

	const filteredPosts = React.useMemo(() => {
		if (posts === null) {
			return posts;
		}
		const now = new Date();
		const filteredPosts = posts.map(mapPost).filter((post) => isPublished(post, locale, now));
		filteredPosts.sort((a, b) => a.content[locale].title.localeCompare(b.content[locale].title));

		const baseOffset = Math.ceil(filteredPosts.length / 3);
		const offsets = [
			0,
			baseOffset,
			baseOffset + Math.ceil((filteredPosts.length - baseOffset) / 2),
		];

		return filteredPosts.map((_, index) => {
			const column = index % 3;
			const row = Math.floor(index / 3);
			const i = offsets[column] + row;
			return filteredPosts[i];
		});
	}, [posts]);

	return person !== null && filteredPosts !== null ? <PersonDetail locale={locale} person={person} posts={filteredPosts} onClose={onClose} /> : null;
};
