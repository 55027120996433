import * as React from 'react';
import { Link } from 'wouter';
import { AuthenticationContext } from 'Authentication';
import { buildImageSourceSet } from 'FirebasePost';
import { Locale } from 'Locale';
import { Post } from 'Post';

export const PostCell: React.FunctionComponent<{
	isTarget?: boolean;
	locale: Locale;
	post: Post;
	link(): string;
	onDelete?(): void;
	onDragEnd?(event: React.DragEvent<HTMLElement>): void;
	onDragEnter?(event: React.DragEvent<HTMLElement>): void;
	onDragStart?(event: React.DragEvent<HTMLElement>): void;
	onDrop?(event: React.DragEvent<HTMLElement>): void;
	onEdit?(): void;
}> = ({ isTarget = false, locale, post, link, onDragEnd, onDragEnter, onDragStart, onDelete, onDrop, onEdit }) => {
	const [loaded, setLoaded] = React.useState(false);
	const authStatus = React.useContext(AuthenticationContext);

	return <article
		className={`article-item fadeout ${loaded || post.poster === null ? 'fadeout-visible' : ''} ${isTarget ? 'drag-target' : ''}`}
		onDragStart={onDragStart}
		onDragEnd={onDragEnd}
		onDragOver={onDragStart !== undefined ? (event) => { event.preventDefault(); } : undefined}
		onDragEnter={onDragEnter}
		onDrop={onDrop}
	>
		<Link href={link()}>
			<a>
				<div>
					<img
						srcSet={buildImageSourceSet(post.poster?.images ?? [], 3)}
						loading='lazy'
						onLoad={() => { setLoaded(true); }} onError={() => { setLoaded(true); }}
					/>
				</div>
				<h1>{post.content[locale].title}</h1>
				{/* <p>{post.tags.map((tag) => tag.title[locale]).join(' / ')}</p> */}
			</a>
		</Link>

		{(authStatus?.isAdmin ?? false) ? <>
			<button className='btn-edit' onClick={onEdit}>edit</button>
			<button className='btn-del' onClick={onDelete}>delete</button>
		</> : null}
	</article>;
};

PostCell.displayName = 'PostCell';
