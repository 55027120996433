import * as React from "react";
import { v4 as uuid } from "uuid";
import { ActivityOverlay } from "UI";
import { formatShortISODateTime } from "DateTime";
import { useBodyClass } from "Document";
import { createStorageUrl } from "Firebase";
import { ContentValues, savePost, Values } from "FirebasePost";
import { cropImage, loadImage, loadImageFromUrl } from "Image";
import { Locale } from "Locale";
import { FirebaseMultiSelect } from "MultiSelect";
import {
  CroppedImage,
  OpenGraphPosterRatio,
  Post,
  Poster,
  PosterRatio,
  PostFile,
  PostImage,
  PostPartType,
  PostStyleBorders,
} from "Post";
import { ColorInput } from "PostEditor/ColorInput";
import { ContentEditor } from "PostEditor/ContentEditor";
import { FilesEditor } from "PostEditor/FilesEditor";
import {
  createResizedImages,
  ImagePreview,
  requestImageUpload,
  shouldPreserveImage,
  uploadImage,
  uploadImages,
} from "PostEditor/Image";
import { ImageCrop, InitialCrop } from "PostEditor/ImageCrop";
import { PeopleEditor } from "PostEditor/PeopleEditor";
import { RowsEditor } from "PostEditor/RowsEditor";

const mapContentValues = (post: Post | null, locale: Locale): ContentValues => {
  const content = post?.content[locale];
  const publishTime = post !== null ? content?.publishTime ?? null : new Date();

  return {
    awards: content?.awards ?? { markup: "", html: "" },
    client: content?.client ?? { markup: "", html: "" },
    cooperation: content?.cooperation ?? { markup: "", html: "" },
    description: content?.description ?? { markup: "", html: "" },
    location: content?.location ?? { markup: "", html: "" },
    publishTime:
      publishTime !== null ? formatShortISODateTime(publishTime) : "",
    published: content?.published ?? false,
    slug: content?.slug ?? "",
    title: content?.title ?? "",
    typefaces: content?.typefaces ?? { markup: "", html: "" },
    year: content?.year ?? "",
  };
};

const prepareContent = (content: { [key in Locale]: ContentValues }): {
  [key in Locale]: ContentValues;
} => {
  const preparedContent = { ...content };
  const slugId = uuid();

  Object.keys(preparedContent).forEach((key) => {
    const locale = key as Locale;
    if (preparedContent[locale].slug === "") {
      preparedContent[locale] = { ...preparedContent[locale], slug: slugId };
    }
  });
  return preparedContent;
};

const InfoTab: React.FunctionComponent<{
  children: React.ReactNode;
  initialExpand?: boolean;
  title: string;
}> = ({ children, initialExpand = false, title }) => {
  const [expand, setExpand] = React.useState(initialExpand);
  const toggle = () => {
    setExpand(!expand);
  };

  return (
    <div className="article-tab">
      <div
        className={expand ? "article-tab-head-act" : "article-tab-head"}
        onClick={toggle}
      >
        <h2>{title}</h2>
      </div>
      <article
        className="article-tab-content"
        style={{ display: expand ? "block" : "none" }}
      >
        {children}
      </article>
    </div>
  );
};

export const PostEditor: React.FunctionComponent<{
  disabled: boolean;
  initialLocale: Locale;
  post: Post | null;
  onCancel(): void;
  onSave(values: Values): Promise<void>;
}> = ({
  disabled = false,
  initialLocale,
  post,
  onCancel,
  onSave,
}): React.ReactElement => {
  useBodyClass("disable-scroll");

  const [saveProgress, setSaveProgress] = React.useState<number | null>(null);
  const [locale, setLocale] = React.useState(initialLocale);
  const [poster, setPoster] = React.useState(post?.poster ?? null);
  const [openGraph, setOpenGraph] = React.useState(
    post?.openGraph ?? { poster: null }
  );
  const [content, setContent] = React.useState<{
    [key in Locale]: ContentValues;
  }>({
    [Locale.CS]: mapContentValues(post, Locale.CS),
    [Locale.EN]: mapContentValues(post, Locale.EN),
  });
  const [tags, setTags] = React.useState(post?.tags ?? []);
  const [categories, setCatgegories] = React.useState(post?.categories ?? []);
  const [people, setPeople] = React.useState(post?.people ?? []);
  const [rows, setRows] = React.useState(post?.rows ?? []);
  const [files, setFiles] = React.useState(post?.files ?? []);
  const [draggedFile, setDraggedFile] = React.useState<PostFile | null>(null);
  const [style, setStyle] = React.useState({
    backgroundColor: post?.style.backgroundColor ?? "#ffffff",
    borders: post?.style.borders ?? null,
    color: post?.style.color ?? "#000000",
  });
  const [imageToCrop, setImageToCrop] = React.useState<{
    aspectRatio: number;
    croppedImage: CroppedImage | null;
    image: PostImage;
    initialCrop: InitialCrop;
    resolve(croppedImage: CroppedImage | null): void;
  } | null>(null);

  const cancel = () => {
    if (
      confirm("Do you really want to close editor? All changes will be lost.")
    ) {
      onCancel();
    }
  };

  const coverFileDropped = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.items[0].getAsFile();
    if (file !== null) {
      updateCoverImage(file);
    }
  };

  const updateCoverImage = async (file: Blob) => {
    setPoster(await uploadPoster(file, PosterRatio));
  };

  const ogFileDropped = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.items[0].getAsFile();
    if (file !== null) {
      updateOgImage(file);
    }
  };

  const updateOgImage = async (file: Blob) => {
    setOpenGraph({
      ...openGraph,
      poster: await uploadPoster(file, OpenGraphPosterRatio),
    });
  };

  const cropPoster = async (
    loadedImage: HTMLImageElement,
    image: PostImage,
    croppedImage: CroppedImage | null,
    targetRatio: number
  ): Promise<Poster | null> =>
    new Promise((resolve) => {
      const percentRatio = 100 / loadedImage.width;
      const initialCrop = {
        width: percentRatio * (croppedImage?.width ?? loadedImage.width),
        x: percentRatio * (croppedImage?.x ?? 0),
        y: (100 / loadedImage.height) * (croppedImage?.y ?? 0),
      };

      const cropped = (croppedImage: CroppedImage | null) => {
        resolve({ croppedImage, images: [], originalImage: image });
      };
      setImageToCrop({
        image,
        aspectRatio: targetRatio,
        croppedImage,
        initialCrop,
        resolve: cropped,
      });
    });

  const uploadPoster = async (
    file: Blob,
    targetRatio: number
  ): Promise<Poster | null> => {
    const loadedImage = await loadImage(file);
    const aspectRatio = loadedImage.width / loadedImage.height;
    const image = await uploadImage(
      { height: loadedImage.height, width: loadedImage.width },
      file
    );
    if (image === null) {
      return null;
    }

    const needsCrop =
      !shouldPreserveImage(image.path) &&
      Math.abs(targetRatio - aspectRatio) >= 0.01;
    if (needsCrop) {
      return cropPoster(loadedImage, image, null, targetRatio);
    }
    return { croppedImage: null, images: [], originalImage: image };
  };

  return (
    <div className="post-edit">
      <div className="post-edit-content">
        <div className="post-edit-header">
          <button
            className="btn-save"
            disabled={disabled}
            onClick={() =>
              onSave({
                categories,
                content: prepareContent(content),
                files,
                openGraph,
                people,
                poster,
                rows,
                style: {
                  backgroundColor:
                    style.backgroundColor !== "" ? style.backgroundColor : null,
                  borders: style.borders,
                  color: style.color !== "" ? style.color : null,
                },
                tags,
              })
            }
          >
            Save
          </button>

          <a
            className="post-btn-cancel"
            onClick={disabled ? undefined : cancel}
          >
            ×
          </a>
          {Object.values(Locale).map((otherLocale) => (
            <button
              className={
                otherLocale !== locale
                  ? undefined
                  : "post-edit-language-current"
              }
              key={otherLocale}
              onClick={() => {
                setLocale(otherLocale);
              }}
            >
              {otherLocale}
            </button>
          ))}
        </div>

        <InfoTab title="Texts" initialExpand>
          <div
            className="item-post-image"
            onDragOver={(event) => {
              event.preventDefault();
            }}
            onDrop={coverFileDropped}
          >
            <div className="item-post-image-label">
              cover: <span>(400x260px)</span>
            </div>{" "}
            <div
              className="post-edit-image"
              onClick={async () => {
                const images = await requestImageUpload(false);
                if (images.length > 0) {
                  updateCoverImage(images[0]);
                }
              }}
            >
              {poster !== null ? (
                <ImagePreview
                  image={poster.croppedImage?.image ?? poster.originalImage}
                />
              ) : null}
              {poster !== null && poster.croppedImage !== null ? (
                <button
                  className="btn-imageedit"
                  onClick={async (e) => {
                    e.stopPropagation();
                    const loadedImage = await loadImageFromUrl(
                      createStorageUrl(poster.originalImage.path)
                    );
                    setPoster(
                      await cropPoster(
                        loadedImage,
                        poster.originalImage,
                        poster.croppedImage ?? null,
                        PosterRatio
                      )
                    );
                  }}
                >
                  ✂
                </button>
              ) : null}
            </div>
          </div>

          <div
            className="item-post-image"
            onDragOver={(event) => {
              event.preventDefault();
            }}
            onDrop={ogFileDropped}
          >
            <div className="item-post-image-label">
              OG image: <span>(1200x630px)</span>
            </div>{" "}
            <div
              className="post-edit-image"
              onClick={async () => {
                const images = await requestImageUpload(false);
                if (images.length > 0) {
                  updateOgImage(images[0]);
                }
              }}
            >
              {openGraph.poster !== null ? (
                <ImagePreview
                  image={
                    openGraph.poster.croppedImage?.image ??
                    openGraph.poster.originalImage
                  }
                />
              ) : null}
              {openGraph.poster !== null &&
              openGraph.poster.croppedImage !== null ? (
                <button
                  className="btn-imageedit"
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (openGraph.poster === null) {
                      return;
                    }
                    const loadedImage = await loadImageFromUrl(
                      createStorageUrl(openGraph.poster.originalImage.path)
                    );
                    setOpenGraph({
                      ...openGraph.poster,
                      poster: await cropPoster(
                        loadedImage,
                        openGraph.poster.originalImage,
                        openGraph.poster.croppedImage ?? null,
                        PosterRatio
                      ),
                    });
                  }}
                >
                  ✂
                </button>
              ) : null}
            </div>
          </div>

          <ContentEditor
            values={content[locale]}
            onChange={(contentValues) => {
              setContent({ ...content, [locale]: contentValues });
            }}
          />

          <div className="item-input-color">
            <label>background color:</label>
            <ColorInput
              color={style.backgroundColor}
              onChange={(backgroundColor) => {
                setStyle({ ...style, backgroundColor });
              }}
            />
          </div>

          <div className="item-input-color">
            <label>text color:</label>
            <ColorInput
              color={style.color}
              onChange={(color) => {
                setStyle({ ...style, color });
              }}
            />
          </div>
        </InfoTab>

        <InfoTab title="Meta">
          <div className="post-edit-selects">
            <div className="item-third">
              <h2>Tags</h2>
              <FirebaseMultiSelect
                collection="tags"
                selected={tags}
                onChange={setTags}
              >
                {(tag) => <>{tag.title[locale]}</>}
              </FirebaseMultiSelect>
            </div>
            <div className="item-third">
              <h2>Categories</h2>
              <FirebaseMultiSelect
                collection="categories"
                selected={categories}
                onChange={setCatgegories}
              >
                {(category) => <>{category.content[locale].title}</>}
              </FirebaseMultiSelect>
            </div>
            <div className="item-third">
              <h2>People</h2>
              <PeopleEditor people={people} onChange={setPeople} />
            </div>

            {/* TODO PICK RELATED POSTS FROM POST LIST */}
          </div>
        </InfoTab>

        <InfoTab title="Cells">
          <div className="post-image-row">
            <div className="item-input">
              <label htmlFor="post-editor-borders">Borders:</label>{" "}
              <select
                id="post-editor-borders"
                value={style.borders ?? ""}
                onChange={(event) => {
                  setStyle({
                    ...style,
                    borders:
                      PostStyleBorders[event.currentTarget.value] ?? null,
                  });
                }}
              >
                <option value="">None</option>
                <option value="standard">Standard</option>
              </select>
            </div>

            <RowsEditor
              files={files}
              rows={rows}
              draggedFile={draggedFile}
              onChange={(rows, files) => {
                setRows(rows);
                setFiles(files);
              }}
              onCrop={async (image, aspectRatio, croppedImage) =>
                new Promise(async (resolve) => {
                  const loadedImage = await loadImageFromUrl(
                    createStorageUrl(image.path)
                  );
                  const percentRatio = 100 / loadedImage.width;
                  const initialCrop = {
                    width:
                      percentRatio * (croppedImage?.width ?? loadedImage.width),
                    x: percentRatio * (croppedImage?.x ?? 0),
                    y: percentRatio * (croppedImage?.y ?? 0),
                  };

                  setImageToCrop({
                    image,
                    aspectRatio,
                    croppedImage,
                    initialCrop,
                    resolve,
                  });
                })
              }
            />

            <FilesEditor
              files={files}
              rows={rows}
              onChange={setFiles}
              onDragStart={setDraggedFile}
              onDragEnd={() => {
                setDraggedFile(null);
              }}
            />
          </div>
        </InfoTab>

        <div className="post-edit-save">
          <a className="btn-cancel" onClick={disabled ? undefined : cancel}>
            Cancel
          </a>
          <button
            disabled={disabled}
            onClick={() =>
              onSave({
                categories,
                content: prepareContent(content),
                files,
                openGraph,
                people,
                poster,
                rows,
                style: {
                  backgroundColor:
                    style.backgroundColor !== "" ? style.backgroundColor : null,
                  borders: style.borders,
                  color: style.color !== "" ? style.color : null,
                },
                tags,
              })
            }
          >
            Save
          </button>
        </div>

        {imageToCrop !== null ? (
          <ImageCrop
            image={imageToCrop.image}
            aspectRatio={imageToCrop.aspectRatio}
            initialCrop={imageToCrop.initialCrop}
            onCancel={
              imageToCrop.croppedImage !== null
                ? () => {
                    setImageToCrop(null);
                    imageToCrop.resolve(imageToCrop.croppedImage);
                  }
                : undefined
            }
            onConfirm={async (percentageCrop) => {
              setSaveProgress(0);
              const image = await loadImageFromUrl(
                createStorageUrl(imageToCrop.image.path)
              );
              const crop = {
                height: (image.height / 100) * percentageCrop.height,
                width: (image.width / 100) * percentageCrop.width,
                x: (image.width / 100) * percentageCrop.x,
                y: (image.height / 100) * percentageCrop.y,
              };
              setSaveProgress(10);

              const croppedImage = await cropImage(image, "image/png", crop);
              setSaveProgress(20);

              const postImage = await uploadImage(
                {
                  height: croppedImage.size.height,
                  width: croppedImage.size.width,
                },
                croppedImage.blob,
                undefined,
                (percentage) => {
                  setSaveProgress(Math.round((80 / 100) * percentage) + 20);
                }
              );
              if (postImage === null) {
                setSaveProgress(null);
                imageToCrop.resolve(null);
                return;
              }

              setImageToCrop(null);
              setSaveProgress(null);
              imageToCrop.resolve({ ...crop, image: postImage });
            }}
          />
        ) : null}

        {saveProgress !== null ? (
          <ActivityOverlay percentage={saveProgress} />
        ) : null}
      </div>
    </div>
  );
};

PostEditor.displayName = "PostEditor";

const resizePoster = async (
  poster: Poster,
  targetWidths: number[]
): Promise<Poster> => {
  const imageFile = poster.croppedImage?.image ?? poster.originalImage;
  if (shouldPreserveImage(imageFile.path)) {
    return { ...poster, images: [imageFile] };
  }

  const image = await loadImageFromUrl(createStorageUrl(imageFile.path));
  return {
    ...poster,
    images: await createResizedImages(image, "image/jpeg", targetWidths),
  };
};

export const FirebasePostEditor: React.FunctionComponent<{
  initialLocale: Locale;
  post: Post | null;
  onClose(values: Values | null): void;
}> = ({ initialLocale, post, onClose }) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const save = async (values: Values): Promise<void> => {
    setIsSaving(true);

    const rows = values.rows.map(async (row) => {
      const parts = row.parts.map(async (part) => {
        if (part.type === PostPartType.Image && part.images.length === 0) {
          const imageFile = part.croppedImage?.image ?? part.originalImage;
          if (shouldPreserveImage(imageFile.path)) {
            return { ...part, images: [imageFile] };
          }

          const image = await loadImageFromUrl(
            createStorageUrl(imageFile.path)
          );
          return {
            ...part,
            images: await createResizedImages(image, "image/jpeg"),
          };
        }
        return part;
      });
      return { ...row, parts: await Promise.all(parts) };
    });

    await savePost(post, {
      ...values,
      openGraph: {
        poster:
          values.openGraph.poster !== null
            ? await resizePoster(values.openGraph.poster, [1200])
            : null,
      },
      poster:
        values.poster !== null
          ? await resizePoster(values.poster, [540, 960, 1280, 1920])
          : null,
      rows: await Promise.all(rows),
    });
    setIsSaving(false);
    onClose(values);
  };

  return (
    <>
      <PostEditor
        post={post}
        initialLocale={initialLocale}
        onCancel={() => {
          onClose(null);
        }}
        onSave={save}
        disabled={isSaving}
      />
      {isSaving ? <ActivityOverlay /> : null}
    </>
  );
};

FirebasePostEditor.displayName = "FirebasePostEditor";
