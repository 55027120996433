import Firebase from 'firebase/app';
//import * as firebase from 'firebase/app';
import * as React from 'react';
import useLocation from 'wouter/use-location';
import { Header } from 'Application/Header';
import { AuthenticationContext } from 'Authentication';
import { useDocumentMeta, useDocumentTitle } from '../../Document';
import { addDocument, deleteDocument, runTransaction } from 'Firebase';
import { useInfoParts } from 'FirebaseInfoPart';
import { DynamicContent, InfoPart } from 'Info';
import { InfoPartEditor } from 'InfoPartEditor';
import { Locale } from 'Locale';
import { AdminPeopleList, PeopleList, Person } from 'Person';
import { FirebasePersonDetail } from 'Person/PersonDetail';
import { FirebasePersonEditor } from 'PersonEditor';
import { Collapse } from 'UI';

export const Info: React.FunctionComponent<{locale: Locale; personId?: string}> = ({ locale, personId = null }) => {
	useDocumentTitle('Info');
	useDocumentMeta('og:title', 'Info');
	useDocumentMeta('og:description', '');
	useDocumentMeta('og:image', '');

	const authStatus = React.useContext(AuthenticationContext);
	const filter = React.useCallback((query: Firebase.firestore.Query) => query.orderBy('orderIndex', 'asc'), []);
	const [infoParts, reload] = useInfoParts(filter);
	const [updatedInfoParts, setUpdatedInfoParts] = React.useState<{[id: string]: InfoPart}>({});

	const currentInfoParts = React.useMemo(() => {
		if (infoParts === null || Object.values(updatedInfoParts).length === 0) {
			return infoParts;
		}
		const currentPosts = infoParts.map((post) => post.id in updatedInfoParts ? updatedInfoParts[post.id] : post);
		currentPosts.sort((a, b) => a.orderIndex - b.orderIndex);
		return currentPosts;
	}, [infoParts, updatedInfoParts]);

	const move = (infoPart: InfoPart, index: number, diff: number) => {
		const nearInfoPart = currentInfoParts?.[index + diff];
		const newUpdatedInfoParts = {...updatedInfoParts};

		if (nearInfoPart !== undefined) {
			newUpdatedInfoParts[nearInfoPart.id] = {...nearInfoPart, orderIndex: infoPart.orderIndex};
			newUpdatedInfoParts[infoPart.id] = {...infoPart, orderIndex: nearInfoPart.orderIndex};
		} else {
			const newIndex = Math.max(0, index + diff);
			newUpdatedInfoParts[infoPart.id] = {...infoPart, orderIndex: newIndex * 100};
		}
		setUpdatedInfoParts(newUpdatedInfoParts);
		runTransaction((firestore, transaction) => Promise.all(
			Object.values(newUpdatedInfoParts)
				.map((infoPart) => transaction.update(firestore.collection('infoParts').doc(infoPart.id), infoPart),
		)));
	};

	const [editor, setEditor] = React.useState<{infoPart: InfoPart | null} | null>(null);

	const closeEditor = () => {
		reload();
		setEditor(null);
	};

	const deletePart = async (infoPart: InfoPart) => {
		if (!confirm('Do you really want to delete this content?')) {
			return;
		}

		await deleteDocument('infoParts', infoPart.id);
		reload();
	};

	return <>
		<Header locale={locale} hideCategoryMenu />
		<section>
			<div className='article'>
				{currentInfoParts?.map((infoPart, index) => editor?.infoPart === infoPart
					? <InfoPartEditor
						key={infoPart.id}
						infoPart={infoPart}
						locale={locale}
						onClose={closeEditor}
					/>
					: <InfoPagePart
						key={infoPart.id}
						index={index}
						infoPart={infoPart}
						locale={locale}
						personId={personId}
						orderControls={authStatus?.isAdmin ?? false ? <>
							{index > 0 ? <button className='btn-up' onClick={() => { move(infoPart, index, -1); }}>up</button> : null}
							{index < (currentInfoParts.length - 1) ? <button className='btn-down' onClick={() => { move(infoPart, index, 1); }}>down</button> : null}
						</> : null}
						onEdit={() => { setEditor({infoPart}); }}
						onDelete={() => { deletePart(infoPart); }}
					/>)}

				{(authStatus?.isAdmin ?? false) && currentInfoParts !== null
					? (editor?.infoPart === null
						? <InfoPartEditor
							locale={locale}
							lastOrderIndex={currentInfoParts[currentInfoParts.length - 1].orderIndex}
							onClose={closeEditor}
						/>
						: <button className='btn-add-new' onClick={() => { setEditor({infoPart: null}); }}>add section</button>)
					: null}
			</div>
		</section>
	</>;
};

const InfoPagePart: React.FunctionComponent<{
	index: number;
	infoPart: InfoPart;
	locale: Locale;
	orderControls: React.ReactNode;
	personId: string | null;
	onDelete(): void;
	onEdit(): void;
}> = ({ index, infoPart, locale, orderControls, personId, onDelete, onEdit }) => {
	const authStatus = React.useContext(AuthenticationContext);
	const {title, body} = infoPart.content[locale];

	if (body === DynamicContent.people) {
		return <PeopleTab index={index} infoPart={infoPart} locale={locale} orderControls={orderControls} personId={personId} />;
	}

	if ('html' in body) {
		return <InfoTab
			key={infoPart.id}
			title={title}
			initialExpand={index === 0 && personId === null}
			trailingContent={orderControls}
		>
			<div dangerouslySetInnerHTML={{__html: body.html}} />
			{authStatus?.isAdmin ?? false ? <>
				<button onClick={onEdit}>edit</button>
				<button onClick={onDelete}>delete</button>
			</> : null}
		</InfoTab>;
	}

	return null;
};

const PeopleTab: React.FunctionComponent<{
	index: number;
	infoPart: InfoPart;
	locale: Locale;
	orderControls: React.ReactNode;
	personId: string | null;
}> = ({ index, infoPart, locale, orderControls, personId }) => {
	const authStatus = React.useContext(AuthenticationContext);
	const [, setLocation] = useLocation();
	const [personEditor, setPersonEditor] = React.useState<{person: Person | null} | null>(null);

	return <>
		{personEditor !== null ? <FirebasePersonEditor
			person={personEditor.person}
			initialLocale={locale}
			onClose={() => { setPersonEditor(null); }}
		/> : null}

		<InfoTab title={infoPart.content[locale].title} initialExpand={index === 0 || personId !== null} trailingContent={orderControls}>
			<div className='article-contact-list'>
				{authStatus?.isAdmin ?? false
					? <AdminPeopleList key={personEditor !== null ? 1 : 0} locale={locale} onEdit={(person) => { setPersonEditor({person}); }} />
					: <PeopleList locale={locale} />}

				{authStatus?.isAdmin ?? false
					? <button onClick={() => { setPersonEditor({person: null}); }}>add person</button>
					: null}

				{personId !== null ? <FirebasePersonDetail
					key={`${personId} ${personEditor !== null ? 1 : 0}`}
					personId={personId}
					locale={locale}
					onClose={() => { setLocation(`/${locale}/info`); }}
				/> : null}
			</div>
		</InfoTab>
	</>;
};

const InfoTab: React.FunctionComponent<{
	children: React.ReactNode;
	disableUserExpand?: boolean;
	initialExpand?: boolean;
	title: React.ReactNode;
	trailingContent?: React.ReactNode;
}> = ({children, disableUserExpand = false, initialExpand = false, title, trailingContent}) => {
	const [expand, setExpand] = React.useState(initialExpand);
	const toggle = () => { setExpand(!expand); };
	const content = (height: number | undefined, ref: React.MutableRefObject<HTMLElement>) => <div className='article-tab'>
		<div className={expand ? 'article-tab-head-act' : 'article-tab-head'}>
			<h2 onClick={disableUserExpand ? undefined : toggle}>{title}</h2>
			{trailingContent}
		</div>
		<article className='article-tab-content' ref={ref} style={{height}}>{children}</article>
	</div>;

	return <Collapse expand={expand}>{content}</Collapse>;
};
