export enum Locale {
	EN = 'en',
	CS = 'cs',
}

export const resolveBrowserLocale = (): Locale => {
	const locale = navigator.language.match(/^([a-z]+)/);
	const key = Object.keys(Locale).find((key) => Locale[key] === locale[1]);
	return key !== undefined ? Locale[key] as Locale : Locale.EN;
};

const dictionary: {[locale in Locale]: {[text in string]: string}} = {
	[Locale.CS]: {
		'Awards': 'Ocenění',
		'Client': 'Klient',
		'Cooperation': 'Spolupráce',
		'Designer': 'Autor',
		'Designers': 'Autoři',
		'Page Not Found': 'Stránka nenalezena',
		'Typefaces': 'Písma',
		'about us': 'o nás',
		'contact': 'kontakt',
		'people': 'lidé',
	},
	[Locale.EN]: {},
};

export const localize = (locale: Locale, text: string): string => dictionary[locale][text] ?? text;

export const selectCardinalPlural = (number: number, forms: string[]): string => number === 1 ? forms[0] : forms[1];
