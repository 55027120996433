import { Category } from 'Category';
import { Locale } from 'Locale';
import { Person, Poster, PostFile, PostOpenGraph, PostRow, PostStyle, Tag } from 'Post';

export interface FirebaseContent extends Readonly<{
	awards: {html: string; markup: string};
	client: {html: string; markup: string};
	cooperation: {html: string; markup: string};
	description: {html: string; markup: string};
	location: {html: string; markup: string};
	published: boolean;
	publishTime: number;
	slug: string;
	title: string;
	typefaces: {html: string; markup: string};
	year: string;
}> {}

export interface FirebasePost extends Readonly<{
	categories: Category[];
	categorySlugs: { [key in Locale]: string[] };
	content: { [key in Locale]: FirebaseContent };
	createdAt: number;
	files: PostFile[];
	id: string;
	openGraph: PostOpenGraph;
	orderIndex: number;
	people: Person[];
	peopleIds: string[];
	poster: Poster | null;
	rows: PostRow[];
	style: PostStyle;
	tags: Tag[];
}> {}
