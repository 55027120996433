import * as React from 'react';
import { Link } from 'wouter';
import { Locale } from 'Locale';
import { Person } from 'Person/Person';

export const PersonCell: React.FunctionComponent<{locale: Locale; person: Person}> = ({ locale, person }) => <>
	<h3 className='person-name'>
		<Link to={`/${locale}/info/${encodeURIComponent(person.id)}`}>{person.firstName} {person.lastName}</Link>
	</h3>
	<span className='position ng-binding'>{person.content[locale].position}</span>
	<a href={`mailto:${person.email}`}>{person.email}</a>
</>;
