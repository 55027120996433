import { Category } from "Category";
import { Locale } from "Locale";
import { LayoutType } from "PostLayout";

export interface Tag
  extends Readonly<{
    id: string;
    title: { [key in Locale]: string };
  }> {}

export interface Person
  extends Readonly<{
    id: string;
    name: string;
  }> {}

export enum PostPartType {
  Empty = "empty",
  Image = "image",
  Video = "video",
  InlineVideo = "inlinevideo",
}

interface Video {
  url: string;
}

export interface PostImage {
  path: string;
  size: { height: number; width: number };
}

export interface PostVideo {
  path: string;
  size: { height: number; width: number };
}

export interface PostRow
  extends Readonly<{
    autoplay: boolean;
    id: string;
    layout: { type: LayoutType };
    parts: PostPart[];
    playInLoop: boolean;
    showBorders: boolean;
  }> {}

export interface CroppedImage {
  height: number;
  image: PostImage;
  width: number;
  x: number;
  y: number;
}

export interface PostPartImage {
  readonly croppedImage: CroppedImage | null;
  readonly id: string;
  readonly images: PostImage[];
  readonly jpgQuality?: number;
  readonly originalImage: PostImage;
  readonly type: PostPartType.Image;
}

export type PostPart =
  | { id: string; type: PostPartType.Empty }
  | PostPartImage
  | { id: string; type: PostPartType.Video; video: Video };

export interface PostImageFile {
  image: PostImage;
  type: PostPartType.Image;
}

export interface PostVideoFile {
  type: PostPartType.Video;
  video: Video;
}

export interface PostInlineVideoFile {
  type: PostPartType.InlineVideo;
  video: PostVideo;
}

// export type PostFile = PostImageFile | PostVideoFile;
export type PostFile = PostImageFile | PostVideoFile | PostInlineVideoFile;

export enum PostStyleBorders {
  standard = "standard",
}

export interface PostStyle
  extends Readonly<{
    backgroundColor: string | null;
    borders: PostStyleBorders | null;
    color: string | null;
  }> {}

export interface PostOpenGraph
  extends Readonly<{
    poster: Poster | null;
  }> {}

export interface Content
  extends Readonly<{
    awards: { html: string; markup: string };
    client: { html: string; markup: string };
    cooperation: { html: string; markup: string };
    description: { html: string; markup: string };
    location: { html: string; markup: string };
    published: boolean;
    publishTime: Date | null;
    slug: string;
    title: string;
    typefaces: { html: string; markup: string };
    year: string;
  }> {}

export interface Poster {
  readonly croppedImage: CroppedImage | null;
  readonly images: PostImage[];
  readonly jpgQuality?: number;
  readonly originalImage: PostImage;
}

export interface Post
  extends Readonly<{
    categories: Category[];
    categorySlugs: { [key in Locale]: string[] };
    content: { [key in Locale]: Content };
    createdAt: Date;
    files: PostFile[];
    id: string;
    openGraph: PostOpenGraph;
    orderIndex: number;
    people: Person[];
    peopleIds: string[];
    poster: Poster | null;
    rows: PostRow[];
    style: PostStyle;
    tags: Tag[];
  }> {}

export const isPublished = (post: Post, locale: Locale, at: Date): boolean => {
  const { publishTime, published } = post.content[locale];
  return (
    published && publishTime !== null && publishTime.getTime() <= at.getTime()
  );
};

export const PosterRatio = 400 / 260;
export const OpenGraphPosterRatio = 1200 / 630;
