import Firebase from "firebase/app";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { createStorageUrl, uploadFile } from "Firebase";
import { loadImage, resizeImage, resolveImageFileExtension, Size } from "Image";
import { PostImage } from "Post";
{
  /*
		import * as admin from "Firebase";

		task.on(Firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
		task.on(Firebase.storage.TaskEvent.STATE_CHANGED, (snapshot: admin.firestore.DocumentSnapshot) => { 
	*/
}

export const ImagePreview: React.FunctionComponent<{ image: PostImage }> = ({
  image,
}) => <img src={createStorageUrl(image.path)} />;

const uploadNewFile = async (
  extension: string,
  file: Blob,
  contentType: string,
  onProgress?: (percentage: number) => void
): Promise<string> =>
  new Promise((resolve, reject) => {
    const task = uploadFile(`public/${uuid()}.${extension}`, file, contentType);
    if (onProgress !== undefined) {
      task.on(Firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
        onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      });
    }
    task
      .then((snapshot) => {
        resolve(snapshot.ref.fullPath);
      })
      .catch(reject);
  });

export const uploadImage = async (
  size: { height: number; width: number },
  file: Blob,
  type?: string,
  onProgress?: (percentage: number) => void
): Promise<PostImage | null> => {
  const extension = resolveImageFileExtension(type ?? file.type);
  if (extension === null) {
    return null;
  }

  return {
    path: await uploadNewFile(extension, file, type ?? file.type, onProgress),
    size,
  };
};

export const uploadImages = async (files: File[]): Promise<PostImage[]> => {
  const postFiles = await Promise.all(
    files.map(async (file) => {
      const image = await loadImage(file);
      return uploadImage({ height: image.height, width: image.width }, file);
    })
  );
  return postFiles.filter((postFile) => postFile !== null) as PostImage[];
};

export const requestImageUpload = async (multiple: boolean): Promise<File[]> =>
  new Promise((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = multiple;
    input.accept =
      ".jpg,.png,.gif,.svg,image/jpeg,image/png,image/gif,image/svg+xml";
    input.addEventListener("change", () => {
      resolve(Array.from(input.files ?? []));
    });
    input.click();
  });

export const DefaultTargetWidths = [1024, 1920, 2560, 3840];

export const createResizedImages = async (
  image: HTMLImageElement,
  type: string,
  targetWidths = DefaultTargetWidths,
  fallback = true
): Promise<PostImage[]> => {
  const extension = resolveImageFileExtension(type);
  if (extension === null) {
    return [];
  }

  const size = new Size(image.width, image.height);
  const widths = targetWidths.filter((width) => width <= size.width);

  return Promise.all(
    (fallback && widths.length === 0 ? [size.width] : widths).map(
      async (width) => {
        const { blob, size } = await resizeImage(image, type, width);
        return {
          path: await uploadNewFile(extension, blob, type),
          size: { width: size.width, height: size.height },
        };
      }
    )
  );
};

export const shouldPreserveImage = (path: string) =>
  path.endsWith(".svg") || path.endsWith(".gif");
