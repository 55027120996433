import * as React from 'react';

export const useDocumentTitle = (title: string) => {
	React.useEffect(() => { document.title = title; }, [title]);
};

export const useBodyClass = (className: string) => {
	React.useEffect(() => {
		document.body.className += ` ${className}`;
		return () => { document.body.className = document.body.className.replace(className, ''); };
	}, [className]);
};

export const useDocumentMeta = (property: string, content: string) => {
	const element = React.useRef<HTMLMetaElement | null>(null);
	React.useEffect(() => {
		if (element.current === null) {
			element.current = document.createElement('meta');
			document.head.appendChild(element.current);
		}

		element.current.setAttribute('property', property);
		element.current.setAttribute('content', content);
		return () => {
			if (element.current !== null) {
				document.head.removeChild(element.current);
				element.current = null;
			}
		};

	}, [property, content]);
};
