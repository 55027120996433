import { Locale } from 'Locale';

export interface Person extends Readonly<{
	content: {
		[locale in Locale]: {
			description: {html: string; markup: string};
			position: string;
		};
	};
	email: string;
	firstName: string;
	id: string;
	internal: boolean;
	lastName: string;
	orderIndex: number;
}> {}
