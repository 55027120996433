import * as React from 'react';
import * as ReactDOM from 'react-dom';

export const Collapse: React.FunctionComponent<{
	expand?: boolean;
	children(height: number | undefined, ref: React.MutableRefObject<HTMLElement | null>): React.ReactElement;
}> = ({ expand = false, children }) => {
	const [height, setHeight] = React.useState<number | undefined>(expand ? undefined : 0);
	const contentRef = React.useRef<HTMLElement>(null);

	React.useLayoutEffect(() => {
		if (contentRef.current === null) {
			return undefined;
		}
		if (expand && height !== undefined) {
			setHeight(contentRef.current.scrollHeight);
			const timeoutId = setTimeout(() => { setHeight(undefined); }, 300);
			return () => { clearTimeout(timeoutId); };
		}
		if (!expand && height !== 0) {
			setHeight(contentRef.current.scrollHeight);
			const frameId = requestAnimationFrame(() => { setHeight(0); });
			return () => { cancelAnimationFrame(frameId); };
		}
	}, [expand]);

	return children(height, contentRef);
};

Collapse.displayName = 'UI.Collapse';

export const ActivityOverlay: React.FunctionComponent<{ percentage?: number }> = ({ percentage }) => {
	const [container, setContainer] = React.useState<HTMLDivElement | null>(null);

	React.useEffect(() => {
		const container = document.createElement('div');
		container.className = 'activity-overlay';
		document.body.appendChild(container);
		setContainer(container);

		return () => { container.parentElement?.removeChild(container); };
	}, []);

	return container !== null ? ReactDOM.createPortal(<p>
		Processing…
		{(percentage ?? 0) > 0 ? <><br />{percentage} %</> : null}
	</p>, container) : null;
};

ActivityOverlay.displayName = 'UI.ActivityOverlay';

export const MarkdownHelp: React.FunctionComponent = () => {
	const columnsText = `<div class="column-list">
	<div class="column column-third"><p>…</p></div>
	</div>

	<div class="column-list">
	<div class="column column-half"><p>…</p></div>
	</div>`;

	return <div className='p-markdowninfo'>
		# heading, **<strong>bold</strong>**, *<i>italic</i>*{' - '}
		<a href='https://www.markdownguide.org/cheat-sheet/'>more about Markdown syntax</a>{' - '}HTML is allowed<br />
		For columns use markup: <code><pre>{columnsText}</pre></code>
	</div>;
};
