import Firebase from 'firebase/app';
import * as React from 'react';
import { FirebasePost } from './FirebasePost';
import { mapPost } from './mapPost';
import { useQuery } from 'Firebase';
import { Post } from 'Post';

export const usePosts = (where: (query: Firebase.firestore.Query) => Firebase.firestore.Query): [Post[] | null, () => Promise<void>] => {
	const [posts, fetch] = useQuery<FirebasePost>('posts', where);
	const mappedPosts = React.useMemo(() => posts !== null ? posts.map(mapPost) : null, [posts]);
	return [mappedPosts, fetch];
};
