import * as React from 'react';
import { useBodyClass } from '../Document';
import { PostCell } from './PostCell';
import { deleteDocument, runTransaction, createStorageUrl } from 'Firebase';
import { mapData, useCategoryPosts } from 'FirebasePost';
import { Locale } from 'Locale';
import { Post } from 'Post';

const PostGrid: React.FunctionComponent<{
	locale: Locale;
	posts: Post[];
	link(post: Post): string;
	onDelete(post: Post): void;
	onEdit(post: Post): void;
	onUpdatePosts(posts: Post[]): void;
}> = ({ locale, link, posts, onDelete, onEdit, onUpdatePosts }) => {
	const [draggedPost, setDraggedPost] = React.useState<Post | null>(null);
	const [targetPost, setTargetPost] = React.useState<Post | null>(null);

	useBodyClass(draggedPost !== null ? 'is-dragging' : '')

	const movePost = () => {
		if (draggedPost === null || targetPost === null) {
			return;
		}

		const draggedPostNewIndex = targetPost.orderIndex + 1;
		const updates = [{...draggedPost, orderIndex: draggedPostNewIndex}];

		const conflictingPostIndex = posts.findIndex((post) => post.orderIndex === draggedPostNewIndex);
		let index = draggedPostNewIndex;
		for (let i = conflictingPostIndex; i >= 0; i -= 1) {
			index += 100;
			updates.push({...posts[i], orderIndex: index});
		}

		onUpdatePosts(updates);
	};

	return <>{posts.map((post) => <PostCell
		key={post.id}
		locale={locale}
		link={() => link(post)}
		post={post}
		isTarget={post === targetPost}
		onDelete={() => { onDelete(post); }}
		onEdit={() => { onEdit(post); }}
		onDragStart={() => { setDraggedPost(post); }}
		onDragEnd={() => {
			setDraggedPost(null);
			setTargetPost(null);
		}}
		onDragEnter={() => {
			if (draggedPost !== null) {
				setTargetPost(draggedPost === post ? null : post);
			}
		}}
		onDrop={(event) => {
			event.preventDefault();
			movePost();
		}}
	/>)}</>;
};

PostGrid.displayName = 'PostGrid';

export const AdminCategoryPostGrid: React.FunctionComponent<{
	category: string | null;
	locale: Locale;
	link(post: Post): string;
	onEdit(post: Post): void;
}> = ({ locale, link, category, onEdit }) => {
	const [posts, fetch] = useCategoryPosts(category, locale);
	const [updatedPosts, setUpdatedPosts] = React.useState<{[id: string]: Post}>({});

	const currentPosts = React.useMemo(() => {
		if (posts === null || Object.values(updatedPosts).length === 0) {
			return posts;
		}
		const currentPosts = posts.map((post) => post.id in updatedPosts ? updatedPosts[post.id] : post);
		currentPosts.sort((a, b) => b.orderIndex - a.orderIndex);
		return currentPosts;
	}, [posts, updatedPosts]);

	const deletePost = async (post: Post) => {
		if (confirm('Do you really want to delete this post?')) {
			await deleteDocument('posts', post.id);
			fetch();
		}
	};

	const updatePosts = async (posts: Post[]) => {
		const newUpdatedPosts = {...updatedPosts};
		posts.forEach((post) => { newUpdatedPosts[post.id] = post; });
		setUpdatedPosts(newUpdatedPosts);

		await runTransaction((firestore, transaction) => Promise.all(
			Object.values(posts)
				.map((post) => transaction.update(firestore.collection('posts').doc(post.id), mapData(post)),
		)));
	};

	return currentPosts !== null ? <PostGrid
		posts={currentPosts}
		locale={locale}
		link={link}
		onEdit={onEdit}
		onDelete={deletePost}
		onUpdatePosts={updatePosts}
	/> : null;
};

AdminCategoryPostGrid.displayName = 'AdminCategoryPostGrid';
