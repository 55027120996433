import Firebase from 'firebase/app';
import 'firebase/auth'; // tslint:disable-line: no-import-side-effect
import 'firebase/firestore'; // tslint:disable-line: no-import-side-effect
import 'firebase/storage'; // tslint:disable-line: no-import-side-effect
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Application } from 'Application';
import { config } from 'config.ts';

Firebase.initializeApp(config.firebase.app);
if (config.firebase.firestore !== null) {
	Firebase.firestore().settings(config.firebase.firestore);
}

ReactDOM.render(<Application />, document.getElementById('application'));
