import Firebase from 'firebase/app';
import * as React from 'react';
import { Link } from 'wouter';
import { HeaderBar } from './HeaderBar';
import { AuthenticationStatus } from 'Authentication';
import { Category, RootCategoryMenu } from 'Category';
import { useQuery } from 'Firebase';
import { Locale } from 'Locale';
import { Post } from 'Post';

export const Header: React.FunctionComponent<{
	color?: string;
	currentCategorySlug?: string | null;
	currentPost?: Post;
	hideCategoryMenu?: boolean;
	locale: Locale;
}> = ({children, color = null, currentCategorySlug = null, currentPost = null, hideCategoryMenu = false, locale}) => {
	const filter = React.useCallback((query: Firebase.firestore.Query) => query.orderBy('orderIndex', 'desc'), []);
	const [categories] = useQuery<Category>('categories', filter);
	const currentCategory = React.useMemo(
		() => categories?.find((category) => category.content[locale].slug === currentCategorySlug) ?? null,
		[locale, currentCategorySlug],
	);

	return <header style={{color}}>
		<h1>
			<Link href={`/${locale}`}>marvil</Link>{' '}
		</h1>
		<Link href={`/${locale}/info`}>info</Link>
		<AuthenticationStatus>{children}</AuthenticationStatus>
		<HeaderBar locale={locale} color={color} currentPost={currentPost} currentCategory={currentCategory} />
		{hideCategoryMenu ? null : <RootCategoryMenu categories={categories} locale={locale} currentCategorySlug={currentCategorySlug} />}
	</header>;
};
