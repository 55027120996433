import Firebase from 'firebase/app';
import marked from 'marked';
import * as React from 'react';
import { useBodyClass } from 'Document';
import { Locale } from 'Locale';
import { Person } from 'Person';
import { addDocument, updateDocument } from 'Firebase';
import { MarkdownHelp } from '../UI';

interface Values {
	content: { [locale in Locale]: {
		description: {html: string; markup: string};
		position: string;
	} };
	email: string;
	firstName: string;
	internal: boolean;
	lastName: string;
}

export const PersonEditor: React.FunctionComponent<{
	disabled: boolean;
	initialLocale: Locale;
	person: Person | null;
	onCancel(): void;
	onSave(values: Values): Promise<void>;
}> = ({ disabled, initialLocale, person, onCancel, onSave }) => {
	useBodyClass('disable-scroll');

	const [locale, setLocale] = React.useState(initialLocale);
	const [values, setValues] = React.useState<Values>({
		content: {
			[Locale.CS]: {
				description: person?.content[Locale.CS].description ?? {html: '', markup: ''},
				position: person?.content[Locale.CS].position ?? '',
			},
			[Locale.EN]: {
				description: person?.content[Locale.EN].description ?? {html: '', markup: ''},
				position: person?.content[Locale.EN].position ?? '',
			},
		},
		email: person?.email ?? '',
		firstName: person?.firstName ?? '',
		internal: person?.internal ?? false,
		lastName: person?.lastName ?? '',
	});

	const cancel = () => {
		if (confirm('Do you really want to close editor? All changes will be lost.')) {
			onCancel();
		}
	};

	return <div className='post-edit'>
		<div className='post-edit-content'>
			<div className='post-edit-header'>
				<button className='btn-save' disabled={disabled} onClick={() => { onSave(values); }}>Save</button>
				<a className='post-btn-cancel' onClick={disabled ? undefined : cancel}>×</a>
				{Object.values(Locale).map((otherLocale) => <button
					className={otherLocale !== locale ? undefined : 'post-edit-language-current'}
					key={otherLocale}
					onClick={() => { setLocale(otherLocale); }}
				>
					{otherLocale}
				</button>)}
			</div>

			<div className='post-edit-params'>
				<div className='item-input'>
					<label htmlFor='person-editor-email'>e-mail:</label>{' '}
					<input
						id='person-editor-email'
						value={values.email}
						onChange={(e) => { setValues({...values, email: e.currentTarget.value}); }}
					/>
				</div>

				<div className='item-input'>
					<label htmlFor='person-editor-first-name'>first name:</label>{' '}
					<input
						id='person-editor-first-name'
						value={values.firstName}
						onChange={(e) => { setValues({...values, firstName: e.currentTarget.value}); }}
					/>
				</div>

				<div className='item-input'>
					<label htmlFor='person-editor-last-name'>last name:</label>{' '}
					<input
						id='person-editor-last-name'
						value={values.lastName}
						onChange={(e) => { setValues({...values, lastName: e.currentTarget.value}); }}
					/>
				</div>

				<div className='item-input'>
					<label htmlFor='person-editor-content-position'>position:</label>{' '}
					<input
						id='person-editor-content-position'
						value={values.content[locale].position}
						onChange={(e) => { setValues({
							...values,
							content: {
								...values.content,
								[locale]: {...values.content[locale], position: e.currentTarget.value},
							},
						}); }}
					/>
				</div>

				<div className='item-radio'><label>
					<input
						type='checkbox'
						checked={values.internal}
						onChange={(e) => { setValues({...values, internal: e.currentTarget.checked}); }}
					/> hide on info page
				</label></div>

				<div className='item-textarea'>
					<label htmlFor='person-editor-content-description'>description:</label>{' '}
					<textarea
						id='person-editor-content-description'
						value={values.content[locale].description.markup}
						onChange={(e) => {
							const markup = e.currentTarget.value;
							setValues({
								...values,
								content: {
									...values.content,
									[locale]: {...values.content[locale], description: {markup, html: marked(markup)}},
								},
							});
						}}
					/>
					<MarkdownHelp />
				</div>
			</div>
		</div>
	</div>;
};

const calculateNewOrderIndex = async (): Promise<number> => {
	const query = Firebase.firestore().collection('people').orderBy('orderIndex', 'desc').limit(1);
	const docs = (await query.get()).docs;
	if (docs.length === 0) {
		return 0;
	}
	const index = (docs[0].data() as {orderIndex: number}).orderIndex + 100;
	return Math.round(index / 100) * 100;
};

export const FirebasePersonEditor: React.FunctionComponent<{
	initialLocale: Locale;
	person: Person | null;
	onClose(): void;
}> = ({ initialLocale, person, onClose }) => {
	const [isSaving, setIsSaving] = React.useState(false);
	const save = async (values: Values): Promise<void> => {
		setIsSaving(true);

		const data = {
			content: values.content,
			email: values.email,
			firstName: values.firstName,
			internal: values.internal,
			lastName: values.lastName,
			orderIndex: person?.orderIndex ?? await calculateNewOrderIndex(),
		};

		if (person !== null) {
			await updateDocument('people', person.id, data);
		} else {
			await addDocument('people', data);
		}

		setIsSaving(false);
		onClose();
	};

	return <PersonEditor disabled={isSaving} initialLocale={initialLocale} person={person} onSave={save} onCancel={onClose} />;
};
