import Firebase from 'firebase/app';
import * as React from 'react';
import useLocation from 'wouter/use-location';
import { NotFound } from './NotFound';
import { Header } from '../Header';
import { AuthenticationContext } from 'Authentication';
import { useBodyClass, useDocumentMeta, useDocumentTitle } from '../../Document';
import { createStorageUrl } from 'Firebase';
import { usePosts } from 'FirebasePost';
import { Locale } from 'Locale';
import { isPublished, Post as PostEntity } from 'Post';
import { PostDetail } from 'PostDetail';
import { FirebasePostEditor } from '../../PostEditor';

export const Post: React.FunctionComponent<{locale: Locale; slug: string}> = ({ locale, slug }) => {
	useBodyClass('post-detail');
	const [_, setLocation] = useLocation();
	const category = (new URLSearchParams(window.location.search)).get('category');

	const [editor, setEditor] = React.useState<{post: PostEntity | null} | null>(null);

	const authStatus = React.useContext(AuthenticationContext);
	const filter = React.useCallback(
		(query: Firebase.firestore.Query) => {
			let modifiedQuery = query.where(`content.${locale}.slug`, '==', slug).limit(1);
			if (authStatus === null || !authStatus.isAdmin) {
				modifiedQuery = modifiedQuery.where(`content.${locale}.published`, '==', true);
			}
			return modifiedQuery;
		},
		[locale, slug, authStatus],
	);

	const [posts, fetch] = usePosts(filter);
	if (posts === null) {
		useDocumentTitle('');
		useDocumentMeta('og:title', '');
		useDocumentMeta('og:image', '');
		useDocumentMeta('og:description', '');
		return null;
	}
	if (posts.length === 0 || !((authStatus?.isAdmin ?? false) || isPublished(posts[0], locale, new Date()))) {
		useDocumentTitle('');
		useDocumentMeta('og:title', '');
		useDocumentMeta('og:image', '');
		useDocumentMeta('og:description', '');
		return <NotFound locale={locale} />;
	}

	const post = posts[0];
	useDocumentTitle(post.content[locale].title);

	const openGraphImage = post.openGraph.poster !== null
		? createStorageUrl((post.openGraph.poster.croppedImage?.image ?? post.openGraph.poster.originalImage).path)
		: '';
	useDocumentMeta('og:title', post.content[locale].title);
	useDocumentMeta('og:description', post.content[locale].description.html);
	useDocumentMeta('og:image', openGraphImage);

	return <>
		{editor !== null ? <FirebasePostEditor
			key={editor.post?.id ?? 'new'}
			initialLocale={locale}
			post={editor.post}
			onClose={(values) => {
				if (values !== null && values.content[locale].slug !== post.content[locale].slug) {
					setLocation(`/${locale}/p/${values.content[locale].slug}`);
				} else if (values !== null) {
					fetch();
				}
				setEditor(null);
			}}
		/> : null}

		<Header
			backgroundColor={post.style.backgroundColor}
			color={post.style.color}
			currentCategorySlug={category}
			currentPost={post}
			locale={locale}
		>
			{(authStatus?.isAdmin ?? false) ? <button className='headerProjectEdit' onClick={() => { setEditor({post}); }}>edit</button> : null}
		</Header>

		<PostDetail post={post} locale={locale} category={category} onClose={() => { setLocation(`/${locale}/`); }} />
	</>;
};
