import Firebase from 'firebase/app';
import 'firebase/auth'; // tslint:disable-line: no-import-side-effect
import * as React from 'react';

export interface Status {
	readonly isAdmin: boolean;
	readonly user: Firebase.User | null;
}

const AnonymousStatus = {isAdmin: false, user: null};
export const AuthenticationContext = React.createContext<Status | null>(null);

export const AuthenticationFirebaseProvider = ({children}: {children: React.ReactNode}) => {
	const [status, setStatus] = React.useState<Status | null>(null);
	React.useEffect(() => Firebase.auth().onAuthStateChanged(async (user) => {
		if (user === null) {
			setStatus(AnonymousStatus);
			return;
		}
		const token = await user.getIdTokenResult();
		setStatus({user, isAdmin: token.claims.admin === true});
	}), []);
	return <AuthenticationContext.Provider value={status}>{children}</AuthenticationContext.Provider>;
};

export const login = () => Firebase.auth().signInWithRedirect(new Firebase.auth.GoogleAuthProvider());
const logout = () => Firebase.auth().signOut();

const UserInfo: React.FunctionComponent<{user: Firebase.User}> = ({children, user}) => <div className='headerAuth'>
	<span>{user.displayName}</span>
	{children}
	<button className='headerAuthLogout' onClick={logout}>logout</button>
</div>;

export const AuthenticationStatus: React.FunctionComponent = ({children}) => <AuthenticationContext.Consumer>
	{(status) => (status?.user?.isAnonymous ?? true) ? null : <UserInfo user={status.user}>{children}</UserInfo>}
</AuthenticationContext.Consumer>;
