import marked from 'marked';
import * as React from 'react';
import { addDocument, updateDocument } from 'Firebase';
import { InfoPart } from 'Info/InfoPart';
import { Locale } from 'Locale';
import { ActivityOverlay, MarkdownHelp } from 'UI';

export const InfoPartEditor: React.FunctionComponent<{
	infoPart?: InfoPart;
	lastOrderIndex?: number;
	locale: Locale;
	onClose(): void;
}> = ({ infoPart = null, lastOrderIndex = 0, locale, onClose }) => {
	const [content, setContent] = React.useState({
		[Locale.CS]: {
			body: infoPart?.content.cs?.body ?? {html: '', markup: ''},
			title: infoPart?.content.cs?.title ?? '',
		},
		[Locale.EN]: {
			body: infoPart?.content.en?.body ?? {html: '', markup: ''},
			title: infoPart?.content.en?.title ?? '',
		},
	});
	const [isSaving, setIsSaving] = React.useState(false);

	const update = (title: string, markup: string) => {
		setContent({
			...content,
			[locale]: {
				body: {html: marked(markup), markup},
				title,
			},
		});
	};

	const submit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSaving(true);

		const document = {
			...(infoPart ?? {orderIndex: lastOrderIndex + 100}),
			content,
		};

		if (infoPart !== null) {
			await updateDocument('infoParts', infoPart.id, document);
		} else {
			await addDocument('infoParts', document);
		}

		setIsSaving(false);
		onClose();
	};

	return <form onSubmit={submit}>
		<div className='article-edit'>
			<div className='article-tab'>
				<div className='article-tab-head-act'>
					<h2>
						<input value={content[locale].title} onChange={(event) => { update(event.currentTarget.value, content[locale].body.markup); }} />
					</h2>
				</div>

				<article className='article-tab-content'>
					<textarea value={content[locale].body.markup} onChange={(event) => { update(content[locale].title, event.currentTarget.value); }} />
					<MarkdownHelp />

					<div className='article-edit-btns'>
						<button>save</button>
						<a role='button' onClick={onClose}>cancel</a>
					</div>
				</article>
			</div>
		</div>

		{isSaving ? <ActivityOverlay /> : null}
	</form>;
};
