import Firebase from 'firebase/app';
import * as React from 'react';
import { FirebasePost } from '~/FirebasePost/FirebasePost';
import { Post } from 'Post';
import { Locale } from 'Locale';

import { mapPost } from './mapPost';

export const useFollowingPost = (orderIndex: number, category: string | null, locale: Locale, direction: 'next' | 'prev') => {
	const [post, setPost] = React.useState<Post | null>(null);

	React.useEffect(() => {
		let cancelled = false;
		let startAt: Firebase.firestore.QueryDocumentSnapshot | null = null;
		let isAtEnd = false;

		(async () => {
			while (true) {
				let query = Firebase.firestore().collection('posts')
					.where(`content.${locale}.published`, '==', true);

				if (!isAtEnd) {
					query = query.where('orderIndex', direction === 'next' ? '<' : '>', orderIndex);
				}
				if (category !== null) {
					query = query.where(`categorySlugs.${locale}`, 'array-contains', category);
				}
				if (startAt !== null) {
					query = query.startAt(startAt);
				}

				query = query.orderBy('orderIndex', direction === 'next' ? 'desc' : 'asc').limit(5);

				const docs = (await query.get()).docs;
				if (cancelled) {
					return;
				}
				if (docs.length === 0) {
					if (isAtEnd) {
						setPost(null);
						return;
					}
					isAtEnd = true;
				}
				const posts = docs.map((doc) => {
					const data = {id: doc.id, ...doc.data()};
					return mapPost(data as FirebasePost);
				});
				const post = posts.find((post) => {
					const {publishTime} = post.content[locale];
					return publishTime !== null && publishTime.getTime() <= Date.now();
				});
				if (post !== undefined) {
					setPost(post);
					return;
				}
				startAt = docs[docs.length - 1] ?? null;
			}
		})();

		return () => { cancelled = true; };
	}, [orderIndex]);

	return post;
};
