import * as React from 'react';
import { PostCell } from './PostCell';
import { useCategoryPosts } from 'FirebasePost';
import { Locale } from 'Locale';
import { Post } from 'Post';
import './styles.less'; // tslint:disable-line: no-import-side-effect

const PostGrid: React.FunctionComponent<{
	locale: Locale;
	posts: Post[];
	link(post: Post): string;
}> = ({ locale, link, posts }) => <>{posts.map((post) => <PostCell
	key={post.id}
	locale={locale}
	link={() => link(post)}
	post={post}
/>)}</>;

PostGrid.displayName = 'PostGrid';

export const CategoryPostGrid: React.FunctionComponent<{
	category: string | null;
	locale: Locale;
	link(post: Post): string;
}> = ({ locale, link, category }) => {
	const [posts] = useCategoryPosts(category, locale);
	return posts !== null ? <PostGrid posts={posts} locale={locale} link={link} /> : null;
};

CategoryPostGrid.displayName = 'CategoryPostGrid';
