import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'; // tslint:disable-line: no-import-side-effect
import { createStorageUrl } from 'Firebase';
import { PostImage } from 'Post';

export interface InitialCrop {
	width: number;
	x: number;
	y: number;
}

export const ImageCrop: React.FunctionComponent<{
	aspectRatio: number;
	image: PostImage;
	initialCrop: InitialCrop;
	onCancel?(): void;
	onConfirm(crop: {height: number; width: number; x: number; y: number}): void;
}> = ({ aspectRatio, image, initialCrop, onCancel = null, onConfirm }) => {
	const [container, setContainer] = React.useState<HTMLDivElement | null>(null);
	const [crop, setCrop] = React.useState<ReactCrop.Crop>({
		aspect: aspectRatio,
		unit: '%',
		...initialCrop,
	});

	React.useEffect(() => {
		const container = document.createElement('div');
		container.className = 'crop-image-container';
		document.body.appendChild(container);
		setContainer(container);

		const resized = () => {
			const padding = 20;
			const ratio = image.size.width / image.size.height;
			const height = Math.min(window.innerHeight, window.innerWidth / ratio) - (padding * 3);
			const width = Math.min(image.size.width, height * ratio) - (padding * 2);
			const currentPadding = (window.innerWidth - width) / 2;

			container.style.width = `${width}px`;
			container.style.left = `${currentPadding}px`;
			container.style.top = `${padding}px`;
		};
		resized();
		window.addEventListener('resize', resized, {passive: true});

		return () => {
			window.removeEventListener('resize', resized);
			container.parentElement?.removeChild(container);
		};
	}, []);

	const confirm = () => {
		if (crop.height !== undefined && crop.width !== undefined && crop.x !== undefined && crop.y !== undefined) {
			onConfirm({
				height: crop.height,
				width: crop.width,
				x: crop.x,
				y: crop.y,
			});
		}
	};

	return container !== null ? ReactDOM.createPortal(<>
		<ReactCrop
			src={createStorageUrl(image.path)}
			crop={crop}
			keepSelection
			onChange={(_, crop) => { setCrop(crop); }}
		/>
		<div className='crop-image-container-action'>
			{onCancel !== null ? <button onClick={onCancel} className='btn-cancel'>Cancel</button> : null}
			<button onClick={confirm}>Save</button>
		</div>
	</>, container) : null;
};

ImageCrop.displayName = 'PostEditor.ImageCrop';
