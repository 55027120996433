import Firebase from 'firebase/app';
import * as React from 'react';
import { PersonCell } from './PersonCell';
import { useQuery } from 'Firebase';
import { Locale } from 'Locale';
import { Person } from 'Person';

export const PeopleList: React.FunctionComponent<{locale: Locale}> = ({ locale }) => {
	const filter = React.useCallback((query: Firebase.firestore.Query) => query.where('internal', '==', false).orderBy('orderIndex', 'desc'), []);
	const [people] = useQuery<Person>('people', filter);

	return <ul>
		{people !== null ? people.map((person) => <li key={person.id}>
			<PersonCell person={person} locale={locale} />
		</li>) : null}
	</ul>;
};
