import Firebase from 'firebase/app';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useCollection = <Document extends {id: string}>(name: string): Document[] | null => {
	const [documents, setDocuments] = useState<Document[] | null>(null);
	useEffect(() => {
		let cancelled = false;

		(async () => {
			const collection = await Firebase.firestore().collection(name).get();
			if (cancelled) {
				return;
			}

			const documents = collection.docs.map((doc) => ({id: doc.id, ...doc.data()}));
			setDocuments(documents as Document[]);
		})();

		return () => { cancelled = true; };
	}, [name]);

	return documents;
};

export const useQuery = <Document extends {id: string}>(
	name: string,
	where: (query: Firebase.firestore.Query) => Firebase.firestore.Query,
): [Document[] | null, () => Promise<void>] => {
	const [documents, setDocuments] = useState<Document[] | null>(null);
	const cancelled = useRef(false);

	const fetch = useCallback(async () => {
		setDocuments(null);

		const collection = await where(Firebase.firestore().collection(name)).get();
		if (cancelled.current) {
			return;
		}
		const documents = collection.docs.map((doc) => ({id: doc.id, ...doc.data()}));
		setDocuments(documents as Document[]);
	}, [name, where]);

	useEffect(() => {
		cancelled.current = false;
		fetch();
		return () => { cancelled.current = true; };
	}, [name, where]);

	return [documents, fetch];
};

export const useDoc = <Document extends {id: string}>(collection: string, id: string): Document | null => {
	const [document, setDocument] = useState<Document | null>(null);

	useEffect(() => {
		let cancelled = false;

		(async () => {
			if (cancelled) {
				return;
			}
			const doc = Firebase.firestore().collection(collection).doc(id);
			setDocument({id, ...(await doc.get()).data()});
		})();

		return () => { cancelled = true; };
	}, [collection, id]);

	return document;
}

export const createStorageUrl = (path: string): string =>
	`https://firebasestorage.googleapis.com/v0/b/web-studio-marvil.appspot.com/o/${encodeURIComponent(path)}?alt=media`;

export const addDocument = async (
	collection: string,
	document: Firebase.firestore.DocumentData,
): Promise<Firebase.firestore.DocumentReference> => Firebase.firestore().collection(collection).add(document);

export const updateDocument = async (
	collection: string,
	id: string,
	document: Firebase.firestore.UpdateData,
): Promise<void> => Firebase.firestore().collection(collection).doc(id).update(document);

export const deleteDocument = async (collection: string, id: string): Promise<void> =>
	Firebase.firestore().collection(collection).doc(id).delete();

export const uploadFile = (path: string, content: Blob | Uint8Array | ArrayBuffer, contentType: string): firebase.storage.UploadTask =>
	Firebase.storage().ref(path).put(content, {contentType});

export const runTransaction = async <Result>(
	updater: (
		firestore: firebase.firestore.Firestore,
		transaction: Firebase.firestore.Transaction,
	) => Promise<Result>,
) => {
	const firestore = Firebase.firestore();
	return firestore.runTransaction((transaction) => updater(firestore, transaction));
};

export const fetchByIds = async <Document extends {id: string}>(collection: string, ids: string[]): Promise<Document[]> =>
	Promise.all(ids.map(async (id): Promise<Document> => {
		const ref = Firebase.firestore().collection(collection).doc(id);
		const doc = await ref.get();
		// tslint:disable-next-line: no-object-literal-type-assertion
		return {id: doc.id, ...doc.data()} as Document;
	}));
