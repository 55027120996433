import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { PostPart, PostPartType } from 'Post';
import 'PostLayout/styles.less'; // tslint:disable-line: no-import-side-effect

export enum LayoutType {
	Full = 'full',
	Wide = 'wide',
	Wide10 = 'wide10',
	FourTwoColumn = 'four-two-column',
	FourTwoColumnFlipped = 'four-two-column-flipped',
	ThirdTwoColumn = 'third-two-column',
	ThirdTwoColumnFlipped = 'third-two-column-flipped',
	QuarterTwoColumn = 'quarter-two-column',
	QuarterTwoColumnFlipped = 'quarter-two-column-flipped',
	VideoTwoColumn = 'video-two-column',
	VideoTwoColumnFlipped = 'video-two-column-flipped',
	ThreeColumn = 'three-column',
	ThreeColumnFlipped = 'three-column-flipped',
	HalfHalf = 'half-half',
	HalfHalfFlipped = 'half-half-flipped',
	SmallHalfHalf = 'small-half-half',
	SmallHalfHalfFlipped = 'small-half-half-flipped',
	LargeHalfHalf = 'large-half-half',
	LargeHalfHalfFlipped = 'large-half-half-flipped',
	DynamicHalfHalf = 'dynamic-half-half',
	VideoThreeColumn = 'video-three-column',
	VideoThreeColumnFlipped = 'video-three-column-flipped',
	QuarterColumn = 'quarter-column',
	QuarterColumnFlipped = 'quarter-column-flipped',
}

export interface LayoutPart {
	aspectRatio: number;
	label: string;
	percentageWidth: number;
}

export interface Layout {
	flipped: LayoutType | null;
	parts: LayoutPart[];
	type: LayoutType;
}

export const Layouts: Layout[] = [
	{
		flipped: null,
		parts: [{aspectRatio: 1, percentageWidth: 100, label: '100%'}],
		type: LayoutType.Full,
	},
	{
		flipped: null,
		parts: [{aspectRatio: 16 / 9, percentageWidth: 100, label: '16:9'}],
		type: LayoutType.Wide,
	},
	{
		flipped: null,
		parts: [{aspectRatio: 16 / 10, percentageWidth: 100, label: '16:10'}],
		type: LayoutType.Wide10,
	},
	{
		flipped: LayoutType.FourTwoColumnFlipped,
		parts: [
			{aspectRatio: 0.8, percentageWidth: 50, label: '50%'},
			{aspectRatio: 1.6, percentageWidth: 50, label: '50%'},
			{aspectRatio: 0.8, percentageWidth: 25, label: '25%'},
			{aspectRatio: 0.8, percentageWidth: 25, label: '25%'},
		],
		type: LayoutType.FourTwoColumn,
	},
	{
		flipped: LayoutType.FourTwoColumn,
		parts: [
			{aspectRatio: 0.8, percentageWidth: 50, label: '50%'},
			{aspectRatio: 1.6, percentageWidth: 50, label: '50%'},
			{aspectRatio: 0.8, percentageWidth: 25, label: '25%'},
			{aspectRatio: 0.8, percentageWidth: 25, label: '25%'},
		],
		type: LayoutType.FourTwoColumnFlipped,
	},
	{
		flipped: LayoutType.ThirdTwoColumnFlipped,
		parts: [
			{aspectRatio: 1.04, percentageWidth: 65, label: '65%'},
			{aspectRatio: 0.56, percentageWidth: 35, label: '35%'},
		],
		type: LayoutType.ThirdTwoColumn,
	},
	{
		flipped: LayoutType.ThirdTwoColumn,
		parts: [
			{aspectRatio: 1.04, percentageWidth: 65, label: '65%'},
			{aspectRatio: 0.56, percentageWidth: 35, label: '35%'},
		],
		type: LayoutType.ThirdTwoColumnFlipped,
	},
	{
		flipped: LayoutType.QuarterTwoColumnFlipped,
		parts: [
			{aspectRatio: 0.4, percentageWidth: 25, label: '25%'},
			{aspectRatio: 1.2, percentageWidth: 75, label: '75%'},
		],
		type: LayoutType.QuarterTwoColumn,
	},
	{
		flipped: LayoutType.QuarterTwoColumn,
		parts: [
			{aspectRatio: 0.4, percentageWidth: 25, label: '25%'},
			{aspectRatio: 1.2, percentageWidth: 75, label: '75%'},
		],
		type: LayoutType.QuarterTwoColumnFlipped,
	},
	{
		flipped: LayoutType.VideoTwoColumnFlipped,
		parts: [
			{aspectRatio: 16 / 9, percentageWidth: 50, label: '50%'},
			{aspectRatio: 16 / 9, percentageWidth: 50, label: '16:9'},
		],
		type: LayoutType.VideoTwoColumn,
	},
	{
		flipped: LayoutType.VideoTwoColumn,
		parts: [
			{aspectRatio: 16 / 9, percentageWidth: 50, label: '50%'},
			{aspectRatio: 16 / 9, percentageWidth: 50, label: '16:9'},
		],
		type: LayoutType.VideoTwoColumnFlipped,
	},
	{
		flipped: LayoutType.ThreeColumnFlipped,
		parts: [
			{aspectRatio: 100 / 125, percentageWidth: 50, label: '50%'},
			{aspectRatio: 100 / 62.5, percentageWidth: 50, label: '16:10'},
			{aspectRatio: 100 / 62.5, percentageWidth: 50, label: '16:10'},
		],
		type: LayoutType.ThreeColumn,
	},
	{
		flipped: LayoutType.ThreeColumn,
		parts: [
			{aspectRatio: 100 / 125, percentageWidth: 50, label: '50%'},
			{aspectRatio: 100 / 62.5, percentageWidth: 50, label: '16:10'},
			{aspectRatio: 100 / 62.5, percentageWidth: 50, label: '16:10'},
		],
		type: LayoutType.ThreeColumnFlipped,
	},
	{
		flipped: LayoutType.HalfHalfFlipped,
		parts: [
			{aspectRatio: 1, percentageWidth: 50, label: '50%'},
			{aspectRatio: 1, percentageWidth: 50, label: '1:1'},
		],
		type: LayoutType.HalfHalf,
	},
	{
		flipped: LayoutType.HalfHalf,
		parts: [
			{aspectRatio: 1, percentageWidth: 50, label: '50%'},
			{aspectRatio: 1, percentageWidth: 50, label: '1:1'},
		],
		type: LayoutType.HalfHalfFlipped,
	},
	{
		flipped: LayoutType.SmallHalfHalfFlipped,
		parts: [
			{aspectRatio: 100 / 62.5, percentageWidth: 50, label: '50%'},
			{aspectRatio: 100 / 62.5, percentageWidth: 50, label: '16:10'},
		],
		type: LayoutType.SmallHalfHalf,
	},
	{
		flipped: LayoutType.SmallHalfHalf,
		parts: [
			{aspectRatio: 100 / 62.5, percentageWidth: 50, label: '50%'},
			{aspectRatio: 100 / 62.5, percentageWidth: 50, label: '16:10'},
		],
		type: LayoutType.SmallHalfHalfFlipped,
	},
	{
		flipped: LayoutType.LargeHalfHalfFlipped,
		parts: [
			{aspectRatio: 100 / 125, percentageWidth: 50, label: '50%'},
			{aspectRatio: 100 / 125, percentageWidth: 50, label: '4:5'},
		],
		type: LayoutType.LargeHalfHalf,
	},
	{
		flipped: LayoutType.LargeHalfHalf,
		parts: [
			{aspectRatio: 100 / 125, percentageWidth: 50, label: '50%'},
			{aspectRatio: 100 / 125, percentageWidth: 50, label: '4:5'},
		],
		type: LayoutType.LargeHalfHalfFlipped,
	},
	{
		flipped: null,
		parts: [
			{aspectRatio: 1, percentageWidth: 50, label: '1:X'},
			{aspectRatio: 1, percentageWidth: 50, label: '1:X'},
		],
		type: LayoutType.DynamicHalfHalf,
	},
	{
		flipped: LayoutType.VideoThreeColumnFlipped,
		parts: [
			{aspectRatio: 100 / 125, percentageWidth: 50, label: '50%'},
			{aspectRatio: 16 / 9, percentageWidth: 50, label: '16:9'},
			{aspectRatio: 960 / 660, percentageWidth: 50, label: '50%'},
		],
		type: LayoutType.VideoThreeColumn,
	},
	{
		flipped: LayoutType.VideoThreeColumn,
		parts: [
			{aspectRatio: 100 / 125, percentageWidth: 50, label: '50%'},
			{aspectRatio: 16 / 9, percentageWidth: 50, label: '16:9'},
			{aspectRatio: 960 / 662, percentageWidth: 50, label: '50%'},
		],
		type: LayoutType.VideoThreeColumnFlipped,
	},
	{
		flipped: LayoutType.QuarterColumnFlipped,
		parts: [
			{aspectRatio: 100 / 125, percentageWidth: 25, label: '25%'},
			{aspectRatio: 100 / 125, percentageWidth: 25, label: '25%'},
			{aspectRatio: 100 / 125, percentageWidth: 25, label: '25%'},
			{aspectRatio: 100 / 125, percentageWidth: 25, label: '25%'},
		],
		type: LayoutType.QuarterColumn,
	},
	{
		flipped: LayoutType.QuarterColumn,
		parts: [
			{aspectRatio: 100 / 125, percentageWidth: 25, label: '25%'},
			{aspectRatio: 100 / 125, percentageWidth: 25, label: '25%'},
			{aspectRatio: 100 / 125, percentageWidth: 25, label: '25%'},
			{aspectRatio: 100 / 125, percentageWidth: 25, label: '25%'},
		],
		type: LayoutType.QuarterColumnFlipped,
	},
];

export const findLayoutForType = (type: LayoutType): Layout => {
	const layout = Layouts.find((layout) => layout.type === type);
	if (layout === undefined) {
		throw new Error();
	}
	return layout;
};

export const PostLayout: React.FunctionComponent<{
	type: LayoutType;
	children(index: number, className: string): React.ReactNode;
// tslint:disable-next-line: cyclomatic-complexity
}> = ({children, type}) => {
	switch (type) {
		case LayoutType.Full:
			return <>{children(0, 'row-fullscreen')}</>;

		case LayoutType.Wide:
			return <>{children(0, 'row-wide')}</>;

		case LayoutType.Wide10:
			return <>{children(0, 'row-wide10')}</>;

		case LayoutType.FourTwoColumn:
			return <>
				<div className='row-half'>
					{children(0, 'row-half-content')}
				</div>
				<div className='row-half'>
					{children(1, 'row-half-full')}
					<div className='row-half-row'>
						<div className='row-half-half'>{children(2, 'row-half-half-content')}</div>
						<div className='row-half-half'>{children(3, 'row-half-half-content')}</div>
					</div>
				</div>
			</>;

		case LayoutType.FourTwoColumnFlipped:
			return <>
				<div className='row-half'>
					{children(1, 'row-half-full')}
					<div className='row-half-row'>
						<div className='row-half-half'>{children(2, 'row-half-half-content')}</div>
						<div className='row-half-half'>{children(3, 'row-half-half-content')}</div>
					</div>
				</div>
				<div className='row-half'>
					{children(0, 'row-half-content')}
				</div>
			</>;

		case LayoutType.ThirdTwoColumn:
			return <>
				<div className='row-two-third'>{children(0, 'row-third-content')}</div>
				<div className='row-one-third'>{children(1, 'row-third-content')}</div>
			</>;

		case LayoutType.ThirdTwoColumnFlipped:
			return <>
				<div className='row-one-third'>{children(1, 'row-third-content')}</div>
				<div className='row-two-third'>{children(0, 'row-third-content')}</div>
			</>;

		case LayoutType.QuarterTwoColumn:
			return <>
				<div className='row-one-quarter'>{children(0, 'row-quarter-content')}</div>
				<div className='row-three-quarter'>{children(1, 'row-quarter-content')}</div>
			</>;

		case LayoutType.QuarterTwoColumnFlipped:
			return <>
				<div className='row-three-quarter'>{children(1, 'row-quarter-content')}</div>
				<div className='row-one-quarter'>{children(0, 'row-quarter-content')}</div>
			</>;

		case LayoutType.VideoTwoColumn:
			return <>
				<div className='row-video-half'>{children(0, 'row-video-content')}</div>
				<div className='row-video-half'>{children(1, 'row-video-content')}</div>
			</>;

		case LayoutType.VideoTwoColumnFlipped:
			return <>
				<div className='row-video-half'>{children(1, 'row-video-content')}</div>
				<div className='row-video-half'>{children(0, 'row-video-content')}</div>
			</>;

		case LayoutType.ThreeColumn:
			return <>
				<div className='row-half'>
					{children(0, 'row-half-content')}
				</div>
				<div className='row-half'>
					{children(1, 'row-half-full')}
					{children(2, 'row-half-full')}
				</div>
			</>;

		case LayoutType.ThreeColumnFlipped:
			return <>
				<div className='row-half'>
					{children(1, 'row-half-full')}
					{children(2, 'row-half-full')}
				</div>
				<div className='row-half'>
					{children(0, 'row-half-content')}
				</div>
			</>;

		case LayoutType.HalfHalf:
			return <>
				<div className='row-half'>{children(0, 'row-half-content-smaller')}</div>
				<div className='row-half'>{children(1, 'row-half-content-smaller')}</div>
			</>;

		case LayoutType.HalfHalfFlipped:
			return <>
				<div className='row-half'>{children(1, 'row-half-content-smaller')}</div>
				<div className='row-half'>{children(0, 'row-half-content-smaller')}</div>
			</>;

		case LayoutType.SmallHalfHalf:
			return <>
				<div className='row-half'>{children(0, 'row-half-full')}</div>
				<div className='row-half'>{children(1, 'row-half-full')}</div>
			</>;

		case LayoutType.SmallHalfHalfFlipped:
			return <>
				<div className='row-half'>{children(1, 'row-half-full')}</div>
				<div className='row-half'>{children(0, 'row-half-full')}</div>
			</>;

		case LayoutType.LargeHalfHalf:
			return <>
				<div className='row-half'>{children(0, 'row-half-content')}</div>
				<div className='row-half'>{children(1, 'row-half-content')}</div>
			</>;

		case LayoutType.LargeHalfHalfFlipped:
			return <>
				<div className='row-half'>{children(1, 'row-half-content')}</div>
				<div className='row-half'>{children(0, 'row-half-content')}</div>
			</>;

		case LayoutType.DynamicHalfHalf:
			return <>
				<div className='row-half'>{children(0, 'row-half-content-dynamic')}</div>
				<div className='row-half'>{children(1, 'row-half-content-dynamic')}</div>
			</>;

		case LayoutType.VideoThreeColumn:
			return <>
				<div className='row-half'>{children(0, 'row-half-content-big')}</div>
				<div className='row-half'>
					{children(1, 'row-video-content')}
					{children(2, 'row-half-full-big')}
				</div>
			</>;

		case LayoutType.VideoThreeColumnFlipped:
			return <>
				<div className='row-half'>
					{children(1, 'row-video-content')}
					{children(2, 'row-half-full-big')}
				</div>
				<div className='row-half'>{children(0, 'row-half-content-big')}</div>
			</>;

		case LayoutType.QuarterColumn:
			return <>
				<div className='row-one-quarter'>{children(0, 'row-quarter-content-half')}</div>
				<div className='row-one-quarter'>{children(1, 'row-quarter-content-half')}</div>
				<div className='row-one-quarter'>{children(2, 'row-quarter-content-half')}</div>
				<div className='row-one-quarter'>{children(3, 'row-quarter-content-half')}</div>
			</>;

		case LayoutType.QuarterColumnFlipped:
			return <>
				<div className='row-one-quarter'>{children(3, 'row-quarter-content-half')}</div>
				<div className='row-one-quarter'>{children(2, 'row-quarter-content-half')}</div>
				<div className='row-one-quarter'>{children(1, 'row-quarter-content-half')}</div>
				<div className='row-one-quarter'>{children(0, 'row-quarter-content-half')}</div>
			</>;

		default:
			throw new Error();
	}
};

export const PostLayoutSelect: React.FunctionComponent<{
	onChange(type: LayoutType): void;
}> = ({onChange}) => <ul className='admin-add-row-list'>
	{[
		LayoutType.Full,
		LayoutType.Wide10,
		LayoutType.FourTwoColumn,
		LayoutType.ThirdTwoColumn,
		LayoutType.QuarterTwoColumn,
		LayoutType.ThreeColumn,
		LayoutType.HalfHalf,
		LayoutType.SmallHalfHalf,
		LayoutType.LargeHalfHalf,
		LayoutType.VideoTwoColumn,
		LayoutType.VideoThreeColumn,
		LayoutType.QuarterColumn,
		LayoutType.Wide,
	].map((type) => {
		const layout = findLayoutForType(type);

		return <li key={type}>
			<button onClick={() => { onChange(type); }}>
				<PostLayout type={type}>{(index, className) => <div
					className={className}
					style={{
						border: '1px solid black',
						paddingBottom: type === LayoutType.DynamicHalfHalf ? 100 : undefined,
					}}
				>
					<span className='admin-row-item-span'>{layout.parts[index].label}</span>
				</div>}</PostLayout>
			</button>
		</li>;
	})}
</ul>;

export const createEmptyParts = (type: LayoutType): PostPart[] =>
	findLayoutForType(type).parts.map((part) => ({
		id: uuid(),
		type: PostPartType.Empty,
	}));

export const calculateStyle = (type: LayoutType, part: PostPart): {paddingBottom?: string} => {
	if (type !== LayoutType.Full && type !== LayoutType.DynamicHalfHalf) {
		return {};
	}

	let aspectRatio = 16 / 9;
	if (part.type === PostPartType.Image) {
		const size = (part.images[0] ?? part.originalImage).size;
		aspectRatio = size.width / size.height;
	}

	return {paddingBottom: `${100 / aspectRatio}%`};
};
