import * as React from 'react';
import { Header } from 'Application/Header';
import { AuthenticationContext } from 'Authentication';
import { useBodyClass, useDocumentMeta, useDocumentTitle } from '../../Document';
import { Locale } from 'Locale';
import { Post } from 'Post';
import { FirebasePostEditor } from 'PostEditor';
import { AdminCategoryPostGrid, CategoryPostGrid } from 'PostGrid';
import { linkPost } from 'Router';

const PostMenu = ({onPost}: {onPost(): void}) => <button className='btn-add-post' onClick={onPost}><span><strong>add post</strong></span></button>;

export const Posts: React.FunctionComponent<{category?: string; locale: Locale}> = ({ locale, category = null }) => {
	const [editor, setEditor] = React.useState<{post: Post | null} | null>(null);
	const post = () => { setEditor({post: null}); };
	const authStatus = React.useContext(AuthenticationContext);

	useBodyClass('posts-grid');
	useDocumentTitle('Studio Marvil');
	useDocumentMeta('og:title', 'Studio Marvil');
	useDocumentMeta('og:description', '');
	useDocumentMeta('og:image', '');

	return <>
		<Header locale={locale} currentCategorySlug={category} />
		<section>
			{authStatus?.isAdmin ?? false ? <>
				<PostMenu onPost={post} />

				{editor !== null ? <FirebasePostEditor
					key={editor.post?.id ?? 'new'}
					initialLocale={locale}
					post={editor.post}
					onClose={() => { setEditor(null); }}
				/> : null}

				<AdminCategoryPostGrid
					key={editor !== null ? 'editing' : undefined}
					locale={locale}
					link={(post) => linkPost(locale, post.content[locale].slug, category)}
					category={category}
					onEdit={(post) => { setEditor({post}); }}
				/>
			</> : <CategoryPostGrid
				locale={locale}
				link={(post) => linkPost(locale, post.content[locale].slug, category)}
				category={category}
			/>}
		</section>
	</>;
};
