import * as React from 'react';
import { Link } from 'wouter';
import useLocation from 'wouter/use-location';
import { AuthenticationContext } from 'Authentication';
import { Category } from 'Category';
import { Locale } from 'Locale';
import { isPublished, Post } from 'Post';

export const HeaderBar: React.FunctionComponent<{
	color: string;
	currentCategory?: Category | null;
	currentPost?: Post | null;
	locale: Locale;
}> = ({color, currentCategory = null, currentPost = null, locale}) => {
	const [location] = useLocation();
	const authStatus = React.useContext(AuthenticationContext);

	return <ul>
		{Object.values(Locale)
			.filter((otherLocale: Locale) => otherLocale !== locale)
			.map((otherLocale) => {
				let href: string;
				if (currentPost !== null) {
					const canAccess = (authStatus?.isAdmin ?? false) || isPublished(currentPost, otherLocale, new Date());
					href = canAccess
						? `/${otherLocale}/p/${currentPost.content[otherLocale].slug}`
						: `/${otherLocale}/`;

				} else if (currentCategory !== null) {
					href = `/${otherLocale}/c/${currentCategory.content[otherLocale].slug}`;

				} else {
					href = location.replace(new RegExp(`^/${locale}(/|$)`), `/${otherLocale}/`);
				}

				return <li key={otherLocale}><Link href={href}>{otherLocale}</Link></li>;
			})}
		{/*
		<li className='social-item'>
			<a href='https://www.facebook.com/Marvil-252736878084458/'>
				<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' fill={color} ng-style='headerStyle'>
					<path d='M40,0C17.9,0,0,17.9,0,40s17.9,40,40,40s40-17.9,40-40S62.1,0,40,0z M52.1,40.9h-7.8v27.7v0H32.7V40.9h-5.8v-9.5h5.8v-5.7 c0-7.8,3.3-12.4,12.6-12.4H53v9.5h-4.8c-3.6,0-3.9,1.3-3.9,3.8v4.8h8.8L52.1,40.9z' />
				</svg>
			</a>
		</li>
		*/}
		<li className='social-item'>
			<a href='https://www.pinterest.com/studiomarvil/'>
				<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' fill={color} ng-style='headerStyle'>
					<path d='M40,0C17.9,0,0,17.9,0,40s17.9,40,40,40s40-17.9,40-40S62.1,0,40,0z M44.1,54.4c-3.1,0-6.2-1.7-7.3-3.8c0,0-1.7,6.9-2.1,8.2 c-1.2,3.5-3,6.9-5.4,9.8c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5-0.1-0.5-0.4C28,64.7,28,60.8,28.7,57l3.9-16.3c-0.6-1.5-1-3.1-1-4.7 c0-4.5,2.5-7.7,5.8-7.7c2.7,0,4,2.1,4,4.5c0,2.8-1.7,6.8-2.7,10.6c-0.1,0.5-0.1,1-0.1,1.5c0.1,2.5,2.3,4.5,4.8,4.4 c5.7,0,9.4-7.3,9.4-15.8c0-6.5-4.4-11.5-12.5-11.5c-0.2,0-0.5,0-0.7,0c-7.9,0.1-14,6.5-14,14.3c-0.1,2.2,0.6,4.2,1.9,5.8 c0.5,0.4,0.7,1.1,0.5,1.7c-0.1,0.6-0.5,1.9-0.6,2.4c0,0.1,0,0.2-0.1,0.4c-0.2,0.5-0.8,0.7-1.3,0.5c-4.2-1.7-6.2-6.3-6.2-11.4 c0-8.5,7.1-18.6,21.3-18.6l0,0l0,0l0,0c11.4,0,18.9,8.2,18.9,17.1C60.2,45.6,53.7,54.4,44.1,54.4z' />
				</svg>
			</a>
		</li>
		<li className='social-item'>
			<a href='https://www.instagram.com/studio_marvil/'>
				<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' fill={color} ng-style='headerStyle'>
					<path d='M48.25,40A8.25,8.25,0,1,1,40,31.75,8.25,8.25,0,0,1,48.25,40Zm11.9-10c.12,2.61.14,3.39.14,10s0,7.39-.14,10a13.66,13.66,0,0,1-.85,4.6,8.16,8.16,0,0,1-4.7,4.7,13.66,13.66,0,0,1-4.6.85c-2.61.12-3.39.14-10,.14s-7.39,0-10-.14a13.66,13.66,0,0,1-4.6-.85,8.16,8.16,0,0,1-4.7-4.7,13.66,13.66,0,0,1-.85-4.6c-.12-2.61-.14-3.39-.14-10s0-7.39.14-10a13.66,13.66,0,0,1,.85-4.6,8.16,8.16,0,0,1,4.7-4.7,13.66,13.66,0,0,1,4.6-.85c2.61-.12,3.39-.15,10-.15s7.39,0,10,.15a13.66,13.66,0,0,1,4.6.85,8.16,8.16,0,0,1,4.7,4.7A13.66,13.66,0,0,1,60.15,30ZM52.71,40A12.71,12.71,0,1,0,40,52.71,12.71,12.71,0,0,0,52.71,40Zm3.48-13.21a3,3,0,1,0-3,3A3,3,0,0,0,56.19,26.79ZM80,40A40,40,0,1,1,40,0,40,40,0,0,1,80,40ZM64.76,40c0-6.72,0-7.57-.15-10.21a18.28,18.28,0,0,0-1.15-6,12.73,12.73,0,0,0-7.24-7.24,18.28,18.28,0,0,0-6-1.15c-2.64-.12-3.49-.15-10.21-.15s-7.57,0-10.21.15a18.28,18.28,0,0,0-6,1.15,12.73,12.73,0,0,0-7.24,7.24,18.28,18.28,0,0,0-1.15,6c-.12,2.64-.15,3.49-.15,10.21s0,7.57.15,10.21a18.28,18.28,0,0,0,1.15,6,12.73,12.73,0,0,0,7.24,7.24,18.28,18.28,0,0,0,6,1.15c2.64.12,3.49.15,10.21.15s7.57,0,10.21-.15a18.28,18.28,0,0,0,6-1.15,12.73,12.73,0,0,0,7.24-7.24,18.28,18.28,0,0,0,1.15-6C64.73,47.57,64.76,46.72,64.76,40Z' />
				</svg>
			</a>
		</li>
	</ul>;
};
