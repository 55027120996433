import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'wouter';
import * as screens from 'Application/screens';
import 'Application/styles.less'; // tslint:disable-line: no-import-side-effect
import { AuthenticationContext, AuthenticationFirebaseProvider, login } from 'Authentication';
import { Locale, resolveBrowserLocale } from 'Locale';

const LoginRedirect: React.FunctionComponent = () => {
	const [_, setLocation] = useLocation();
	const status = React.useContext(AuthenticationContext);

	React.useEffect((): void => {
		if (status === null) {
			return;
		}
		if (status.user === null) {
			login();
		} else {
			setLocation('/');
		}
	}, [status]);

	return null;
};

export const Application = (): React.ReactElement =>
	<AuthenticationFirebaseProvider>
		<Switch>
			<Route path='/login'><LoginRedirect /></Route>
			<Route path='/cs'>{() => <screens.Posts locale={Locale.CS} />}</Route>
			<Route path='/en'>{() => <screens.Posts locale={Locale.EN} />}</Route>
			<Route path='/cs/info'>{() => <screens.Info locale={Locale.CS} />}</Route>
			<Route path='/en/info'>{() => <screens.Info locale={Locale.EN} />}</Route>
			<Route path='/cs/info/:personId'>{({personId}) => <screens.Info locale={Locale.CS} personId={personId} />}</Route>
			<Route path='/en/info/:personId'>{({personId}) => <screens.Info locale={Locale.EN} personId={personId} />}</Route>
			<Route path='/cs/c/:category'>{({category}: {category: string}) =>
				<screens.Posts locale={Locale.CS} category={category} />
			}</Route>
			<Route path='/en/c/:category'>{({category}: {category: string}) =>
				<screens.Posts locale={Locale.EN} category={category} />
			}</Route>
			<Route path='/cs/p/:slug'>{({slug}: {slug: string}) =>
				<screens.Post locale={Locale.CS} slug={slug} />
			}</Route>
			<Route path='/en/p/:slug'>{({slug}: {slug: string}) =>
				<screens.Post locale={Locale.EN} slug={slug} />
			}</Route>
			<Route path='/'>
				<Redirect to={`/${resolveBrowserLocale()}`} />
			</Route>
			<Route path='/cs/:foo*'>{() => <screens.NotFound locale={Locale.CS} />}</Route>
			<Route path='/en/:foo*'>{() => <screens.NotFound locale={Locale.EN} />}</Route>
			<Route path='/:foo*'>{() => <screens.NotFound locale={resolveBrowserLocale()} />}</Route>
		</Switch>
	</AuthenticationFirebaseProvider>;
