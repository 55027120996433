import * as React from "react";
import {
  PostFile,
  PostImage,
  PostImageFile,
  PostVideo,
  PostInlineVideoFile,
  PostPartType,
  PostRow,
} from "Post";
import {
  ImagePreview,
  requestImageUpload,
  uploadImages,
} from "PostEditor/Image";
import { ActivityOverlay } from "UI";

import { default as _ReactPlayer } from "react-player/lazy";
import { ReactPlayerProps } from "react-player/types/lib";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;
// import ReactPlayer from "react-player/vimeo";

const extractFiles = (rows: PostRow[]): Set<string> =>
  rows.reduce<Set<string>>((files, row) => {
    row.parts.forEach((part) => {
      {
        /* TODO 			
			if (part.type === PostPartType.InlineVideo) {
				files.add(part.video.url);
			}
			*/
      }
      if (part.type === PostPartType.Image) {
        files.add(part.originalImage.path);
      } else if (part.type === PostPartType.Video) {
        files.add(part.video.url);
      }
    });
    return files;
  }, new Set());

const calculateGreatestCommonFactor = (a: number, b: number): number =>
  b === 0 ? a : calculateGreatestCommonFactor(b, a % b);

const formatAspectRatio = (width: number, height: number): string => {
  const commonFactor = calculateGreatestCommonFactor(height, width);
  let lowestNumerator = width / commonFactor;
  let lowestDenominator = height / commonFactor;
  if (lowestNumerator === 8) {
    lowestNumerator *= 2;
    lowestDenominator *= 2;
  }

  return `${lowestNumerator}:${lowestDenominator}`;
};

export const FilesEditor: React.FunctionComponent<{
  files: PostFile[];
  rows: PostRow[];
  onChange(files: PostFile[]): void;
  onDragEnd(): void;
  onDragStart(file: PostFile): void;
}> = ({ files, rows, onChange, onDragEnd, onDragStart }) => {
  const usedFiles = React.useMemo(() => extractFiles(rows), [rows]);
  const [isUploading, setIsUploading] = React.useState(false);

  const trackedUploadImages = async (files: File[]): Promise<PostImage[]> => {
    setIsUploading(true);
    const result = await uploadImages(files);
    setIsUploading(false);
    return result;
  };
  {
    /*
    TODO
    const trackedUploadVideos = async (files: File[]): Promise<PostVideo[]> => {
      setIsUploading(true);
      const result = await uploadVideos(files);
      setIsUploading(false);
      return result;
    };
    ENDTODO
    */
  }

  const addImageFiles = (newFiles: PostImage[]) => {
    const imageFiles: PostImageFile[] = newFiles.map((file) => ({
      image: file,
      type: PostPartType.Image,
    }));
    onChange([...files, ...imageFiles]);
  };

  const addVideoFiles = (newFiles: PostVideo[]) => {
    const videoFiles: PostInlineVideoFile[] = newFiles.map((file) => ({
      video: file,
      type: PostPartType.InlineVideo,
    }));
    onChange([...files, ...videoFiles]);
  };

  const removeFile = (fileToRemove: PostFile) => {
    onChange(files.filter((file) => file !== fileToRemove));
  };

  const upload = async () => {
    addImageFiles(await trackedUploadImages(await requestImageUpload(true)));
  };

  const uploadVideo = async () => {
    addVideoFiles(await trackedUploadImages(await requestImageUpload(true)));
    {
      /*
      TODO
      addVideoFiles(await trackedUploadedVideos(await requestVideoUpload(true)));   
      ENDTODO   
      */
    }
  };

  const addVideo = () => {
    const code = prompt("Zadejte kód Vimeo videa");
    if (code === null) {
      return;
    }
    onChange([
      ...files,
      {
        type: PostPartType.Video,
        video: { url: `https://player.vimeo.com/video/${code}` },
      },
    ]);
  };

  /* TODO ADD INLINE VIDEO */
  /* TODO ADD IMG POSTER FOR INLINE VIDEO */

  const filesDropped = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.items)
      .map((dataTransfer) => dataTransfer.getAsFile())
      .filter((file) => file !== null) as File[];
    addImageFiles(await trackedUploadImages(files));
    {
      /* TODO
    addVideoFiles(await trackedUploadedVideos(files));
      */
    }
  };

  return (
    <div
      className="files-editor"
      onDragOver={(event) => {
        event.preventDefault();
      }}
      onDrop={filesDropped}
    >
      {isUploading ? <ActivityOverlay /> : null}

      <button className="btn-upload" onClick={upload}>
        Upload pictures
      </button>
      <button className="btn-upload" onClick={addVideo}>
        Add Vimeo
      </button>
      {/* 
      <button className="btn-upload" onClick={uploadVideo}>
        Upload MP4 Video
      </button>
      */}
      {files.map((file) => {
        if (
          file.type === PostPartType.Image &&
          !usedFiles.has(file.image.path)
        ) {
          return (
            <div
              key={file.image.path}
              draggable
              onDragStart={() => {
                onDragStart(file);
              }}
              onDragEnd={(event) => {
                event.preventDefault();
                onDragEnd();
              }}
            >
              <p>
                {formatAspectRatio(
                  file.image.size.width,
                  file.image.size.height
                )}
              </p>
              <button
                className="btn-remove"
                onClick={() => {
                  removeFile(file);
                }}
              >
                ×
              </button>
              <ImagePreview image={file.image} />
            </div>
          );
        }
        if (
          file.type === PostPartType.Video &&
          !usedFiles.has(file.video.url)
        ) {
          return (
            <div
              className="files-editor-video-file"
              key={file.video.url}
              draggable
              onDragStart={() => {
                onDragStart(file);
              }}
              onDragEnd={(event) => {
                event.preventDefault();
                onDragEnd();
              }}
            >
              <button
                className="btn-remove"
                onClick={() => {
                  removeFile(file);
                }}
              >
                ×
              </button>
              {/*
              <div className="videoPlayer">
                <ReactPlayer
                  url={file.video.url}
                  controls
                  width="100%"
                  height="100%"
                  // playing={true}
                />
              </div>
              
              */}
              <iframe src={file.video.url} style={{ pointerEvents: "none" }} />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

FilesEditor.displayName = "PostEditor.FilesEditor";
