import * as React from 'react';
import Select from 'react-select';
import { useCollection } from 'Firebase';
import { Person as CompletePerson } from 'Person';
import { Person } from 'Post';

export const PeopleEditor: React.FunctionComponent<{
	people: Person[];
	onChange(people: Person[]): void;
}> = ({ people, onChange }) => {
	const availablePeople = useCollection<CompletePerson>('people');
	const usedIds = React.useMemo(() => new Set(people.map((person) => person.id)), [people]);

	const remove = (personToRemove: Person) => {
		onChange(people.filter((person) => person !== personToRemove));
	};

	return <>
		<Select
			options={availablePeople ?? []}
			getOptionLabel={(person: CompletePerson) => `${person.firstName} ${person.lastName}`}
			getOptionValue={(person: CompletePerson) => person.id}
			menuPlacement={'top'}
			menuPortalTarget={document.body}
			menuPosition={'absolute'}
			styles={{menuPortal: (base) => ({ ...base, zIndex: 9999 })}}
			isOptionDisabled={(person: CompletePerson) => usedIds.has(person.id)}
			onChange={(person) => {
				if (person === null || person === undefined || !('id' in person)) {
					return;
				}
				onChange([...people, {
					id: person.id,
					name: `${person.firstName} ${person.lastName}`,
				}]);
			}}
		/>

		{people.map((person) => <p className='people-list' key={person.id}>
			<span role='button' onClick={() => { remove(person); }}>✕</span> {person.name}
		</p>)}
	</>;
};
