import { marked } from 'marked';
import * as React from 'react';
import slugify from 'slugify';
import { ContentValues } from 'FirebasePost';
import { MarkdownHelp } from 'UI';

export const ContentEditor: React.FunctionComponent<{
	values: ContentValues;
	onChange(values: ContentValues): void;
}> = ({values, onChange}) => <div className='post-edit-params'>
	<div className='item-input'>
		<label htmlFor='post-editor-content-title'>title:</label>{' '}
		<input
			id='post-editor-content-title'
			value={values.title}
			onChange={(e) => {
				onChange({
					...values,
					slug: slugify(e.currentTarget.value, {lower: true}),
					title: e.currentTarget.value,
				});
			}}
		/>
	</div>
	<p className='p-slug'>{values.slug}</p>
	<div className='item-input'>
		<label htmlFor='post-editor-content-location'>location:</label>{' '}
		<input id='post-editor-content-location' value={values.location.markup} onChange={(e) => {
			const markup = e.currentTarget.value;
			onChange({...values, location: {markup, html: marked(markup)}});
		}} />
	</div>
	<div className='item-input'>
		<label htmlFor='post-editor-content-year'>year:</label>{' '}
		<input id='post-editor-content-year' value={values.year} onChange={(e) => { onChange({...values, year: e.currentTarget.value}); }} />
	</div>
	<div className='item-input'>
		<label htmlFor='post-editor-content-client'>client:</label>{' '}
		<input id='post-editor-content-client' value={values.client.markup} onChange={(e) => {
			const markup = e.currentTarget.value;
			onChange({...values, client: {markup, html: marked(markup)}});
		}} />
	</div>
	<div className='item-input'>
		<label htmlFor='post-editor-content-typefaces'>typefaces:</label>{' '}
		<input id='post-editor-content-typefaces' value={values.typefaces.markup} onChange={(e) => {
			const markup = e.currentTarget.value;
			onChange({...values, typefaces: {markup, html: marked(markup)}});
		}} />
	</div>
	<div className='item-textarea'>
		<label htmlFor='post-editor-content-description'>description:</label>{' '}
		<textarea id='post-editor-content-description' value={values.description.markup} onChange={(e) => {
			const markup = e.currentTarget.value;
			onChange({...values, description: {markup, html: marked(markup)}});
		}} />
	</div>
	<div className='item-input'>
		<label htmlFor='post-editor-content-cooperation'>cooperation:</label>{' '}
		<textarea id='post-editor-content-cooperation' value={values.cooperation.markup} onChange={(e) => {
			const markup = e.currentTarget.value;
			onChange({...values, cooperation: {markup, html: marked(markup)}});
		}} />
	</div>
	<div className='item-input'>
		<label htmlFor='post-editor-content-awards'>awards:</label>{' '}
		<textarea id='post-editor-content-awards' value={values.awards.markup} onChange={(e) => {
			const markup = e.currentTarget.value;
			onChange({...values, awards: {markup, html: marked(markup)}});
		}} />
	</div>

	<MarkdownHelp />

	<div className='item-radio'><label>
		<input
			type='checkbox'
			checked={values.published}
			onChange={(e) => { onChange({...values, published: e.currentTarget.checked}); }}
		/> publish
	</label></div>

	<div className='item-input'>
		<label htmlFor='post-editor-publish-date'>publish date:</label>{' '}
		<input
			id='post-editor-publish-date'
			type='datetime-local'
			value={values.publishTime}
			onChange={(e) => { onChange({...values, publishTime: e.currentTarget.value}); }}
		/>
	</div>
</div>;

ContentEditor.displayName = 'ContentEditor';
