import * as React from 'react';
import { SketchPicker } from 'react-color';

export const ColorInput: React.FunctionComponent<{color: string; onChange(color: string): void}> = ({ color, onChange }) => {
	const [show, setShow] = React.useState(false);
	const [transientColor, setTransientColor] = React.useState(color);
	const popoverRef = React.useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (
				!(event.target instanceof HTMLElement)
				|| event.target === popoverRef.current
				|| (popoverRef.current?.contains(event.target) ?? false)
			) {
				return;
			}
			setShow(false);
		};
		document.body.addEventListener('click', handler);

		return () => { document.body.removeEventListener('click', handler); };
	}, []);

	return <span ref={popoverRef}>
		<div style={{display: 'inline-block', width: 30, height: 20, border: '1px solid silver', padding: 1}} onClick={() => { setShow(!show); }}>
			<div style={{backgroundColor: transientColor, width: '100%', height: '100%'}} />
		</div>
		{show ? <div style={{position: 'absolute', zIndex: 999}}>
			<SketchPicker
				disableAlpha
				color={transientColor}
				onChange={(color) => { setTransientColor(color.hex); }}
				onChangeComplete={(color) => { onChange(color.hex); }}
			/>
		</div> : null}
	</span>;
};
