import * as React from 'react';
import { Link } from 'wouter';
import { Locale } from 'Locale';

export interface Category {
	content: {
		[key in Locale]: { slug: string; title: string };
	};
	id: string;
}

export const CategoryMenu: React.FunctionComponent<{
	categories: Category[];
	currentCategory?: string | null;
	locale: Locale;
}> = ({ categories, currentCategory = null, locale }) => <nav>
	<ul>
		{categories.map((category) => {
			const {slug, title} = category.content[locale];

			return <li key={category.id} className={slug === currentCategory ? 'current-category-link' : undefined}>
				<Link href={slug === currentCategory ? `/${locale}/` : `/${locale}/c/${slug}`}><a>{title}</a></Link>
			</li>;
		})}
	</ul>
</nav>;

export const RootCategoryMenu: React.FunctionComponent<{
	categories: Category[] | null;
	currentCategorySlug: string | null;
	locale: Locale;
}> = ({ categories, currentCategorySlug, locale }) => <div className={`fadeout ${categories !== null ? 'fadeout-visible' : ''}`}>
	{categories !== null ? <CategoryMenu locale={locale} categories={categories} currentCategory={currentCategorySlug} /> : null}
</div>;
