import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { loadImage } from 'Image';
import { CroppedImage, PostFile, PostImage, PostPart, PostPartType, PostRow } from 'Post';
import { ImagePreview, requestImageUpload, shouldPreserveImage, uploadImage } from 'PostEditor/Image';
import { calculateStyle, createEmptyParts, findLayoutForType, Layout, LayoutPart, LayoutType, PostLayout, PostLayoutSelect } from 'PostLayout';

const Row: React.FunctionComponent<{
	row: PostRow;
	children(part: PostPart, layoutPart: LayoutPart, className: string): React.ReactElement;
	onDragEnd(): void;
	onDragStart(): void;
	onLayoutChange(type: LayoutType): void;
	onRemove(): void;
}> = ({row, children, onDragEnd, onDragStart, onLayoutChange, onRemove}) => {
	const [draggable, setDraggable] = React.useState(false);
	const layout = findLayoutForType(row.layout.type);
	const flippedLayout = layout.flipped;

	return <div
		draggable={draggable}
		onDragStart={(event) => {
			if (!draggable) {
				event.preventDefault();
				return;
			}
			onDragStart();
		}}
		onDragEnd={() => {
			setDraggable(false);
			onDragEnd();
		}}
		className='row'
	>
		<PostLayout type={row.layout.type}>{(index, className) => children(row.parts[index], layout.parts[index], className)}</PostLayout>
		<div className='admin-row-action'>
			<button onClick={onRemove}>×</button>
			<div className='btn-draggable' onMouseDown={() => { setDraggable(true); }}>☰</div>
			{flippedLayout !== null ? <button className='btn-switch' onClick={() => { onLayoutChange(flippedLayout); }}>↹</button> : null}
		</div>
	</div>;
};

export const RowsEditor: React.FunctionComponent<{
	draggedFile: PostFile | null;
	files: PostFile[];
	rows: PostRow[];
	onChange(rows: PostRow[], files: PostFile[]): void;
	onCrop(image: PostImage, aspectRation: number, croppedImage: CroppedImage | null): Promise<CroppedImage | null>;
}> = ({draggedFile, files, rows, onChange, onCrop}) => {
	const [showAdd, setShowAdd] = React.useState({prepend: false, append: false});
	const [draggedRow, setDraggedRow] = React.useState<PostRow | null>(null);
	const [targetRow, setTargetRow] = React.useState<'last' | PostRow | null>(null);
	const [targetPart, setTargetPart] = React.useState<PostPart | null>(null);

	const addRow = (type: LayoutType, prepend: boolean = false) => {
		const newRow: PostRow = {
			autoplay: true,
			id: uuid(),
			layout: {type},
			parts: createEmptyParts(type),
			playInLoop: true,
			showBorders: true,
		};
		onChange(prepend ? [newRow, ...rows] : [...rows, newRow], files);
	};

	const removeRow = (rowIndex: number) => {
		const updatedRows = [...rows];
		updatedRows.splice(rowIndex, 1);
		onChange(updatedRows, files);
	};

	const update = (rowIndex: number, part: PostPart, updatedPart: PostPart) => {
		const updatedRows = [...rows];
		const row = updatedRows[rowIndex];
		updatedRows[rowIndex] = {
			...row,
			parts: row.parts.map((otherPart) => otherPart === part ? updatedPart : otherPart),
		};
		onChange(updatedRows, files);
	};

	const upload = async (rowIndex: number, part: PostPart) => {
		const [file] = await requestImageUpload(false);
		const image = await loadImage(file);
		const imageFile = await uploadImage({height: image.height, width: image.width}, file);
		if (imageFile === null) {
			return;
		}

		const updatedRows = [...rows];
		const row = updatedRows[rowIndex];
		const partIndex = row.parts.findIndex((otherPart) => otherPart === part);
		const layout = findLayoutForType(row.layout.type);
		const croppedImage = await crop(row.layout.type, layout.parts[partIndex], imageFile);

		const updatedParts = [...row.parts];
		updatedParts[partIndex] = {
			croppedImage,
			id: uuid(),
			images: [],
			originalImage: imageFile,
			type: PostPartType.Image,
		};

		updatedRows[rowIndex] = {...row, parts: updatedParts};
		onChange(updatedRows, [...files, {image: imageFile, type: PostPartType.Image}]);
	};

	const updateLayout = (rowIndex: number, type: LayoutType, flags: {autoplay?: boolean; playInLoop?: boolean; showBorders?: boolean}) => {
		const updatedRows = [...rows];
		const row = updatedRows[rowIndex];
		updatedRows[rowIndex] = {...row, layout: {type}, ...flags};
		onChange(updatedRows, files);
	};

	const moveRow = () => {
		if (draggedRow === null || targetRow === null) {
			return;
		}

		const updatedRows = rows.filter((row) => row !== draggedRow);
		if (targetRow === 'last') {
			updatedRows.push(draggedRow);
		} else {
			const index = updatedRows.findIndex((row) => row === targetRow);
			updatedRows.splice(index, 0, draggedRow);
		}
		onChange(updatedRows, files);
	};

	const needsCrop = (layout: LayoutType, partLayout: LayoutPart, image: PostImage): boolean => {
		if (layout === LayoutType.Full || layout === LayoutType.DynamicHalfHalf || shouldPreserveImage(image.path)) {
			return false;
		}
		const aspectRatio = image.size.width / image.size.height;
		return Math.abs(partLayout.aspectRatio - aspectRatio) >= 0.01;
	};

	const crop = async (layout: LayoutType, partLayout: LayoutPart, image: PostImage, croppedImage: CroppedImage | null = null): Promise<CroppedImage | null> =>
		needsCrop(layout, partLayout, image) ? onCrop(image, partLayout.aspectRatio, croppedImage) : null;

	return <div className='admin-row'>
		{showAdd.prepend ? <PostLayoutSelect
			onChange={(type) => {
				setShowAdd({prepend: false, append: false});
				addRow(type, true);
			}}
		/> : (rows.length > 0 ? <button className='btn-add-row' onClick={() => { setShowAdd({prepend: true, append: false}); }}>+</button> : null)}

		{rows.map((row: PostRow, rowIndex) => <div
			key={row.id}
			className='admin-row-item'
			style={{
				borderBottom: targetRow === 'last' && rowIndex === (rows.length - 1) ? '5px solid #00eda4' : undefined,
				borderTop: targetRow === row ? '5px solid #00eda4' : undefined,
			}}
			onDragOver={(e) => { e.preventDefault(); }}
			onDragEnter={() => {
				if (draggedRow !== null) {
					setTargetRow(draggedRow === row ? null : row);
				}
			}}
			onDrop={(e) => {
				e.preventDefault();
				moveRow();
			}}
		>
			<Row
				row={row}
				onRemove={() => { removeRow(rowIndex); }}
				onDragStart={() => { setDraggedRow(row); }}
				onDragEnd={() => {
					setDraggedRow(null);
					setTargetRow(null);
				}}
				onLayoutChange={(type) => { updateLayout(rowIndex, type, {}); }}
			>{(part, layout, className) =>
				<div
					className={className}
					style={{
						opacity: draggedFile !== null && part === targetPart ? 0.5 : 1,
						...calculateStyle(row.layout.type, part),
					}}
					onClick={() => { upload(rowIndex, part); }}
					onDragOver={(e) => { e.preventDefault(); }}
					onDragEnter={() => {
						if (draggedFile !== null) {
							setTargetPart(part);
						}
					}}
					onDrop={async (e) => {
						e.preventDefault();
						if (targetPart === null || draggedFile === null) {
							return;
						}
						update(
							rowIndex,
							targetPart,
							draggedFile.type === PostPartType.Image ? {
								...targetPart,
								croppedImage: await crop(row.layout.type, layout, draggedFile.image),
								images: [],
								originalImage: draggedFile.image,
								type: PostPartType.Image,
							} : {
								...targetPart,
								type: PostPartType.Video,
								video: draggedFile.video,
							},
						);
					}}
				>
					<span className='admin-row-item-span'>{layout.label}</span>

					{part.type === PostPartType.Image ? <ImagePreview image={part.croppedImage?.image ?? part.originalImage} /> : null}
					{part.type === PostPartType.Video ? <iframe src={part.video.url} /> : null}
					{part.type !== PostPartType.Empty ? <button
						className='btn-remove'
						onClick={(e) => {
							e.stopPropagation();
							update(rowIndex, part, {...part, type: PostPartType.Empty});
						}}
					>×</button> : null}

					{part.type === PostPartType.Image && needsCrop(row.layout.type, layout, part.originalImage) ? <button
						className='btn-imageedit'
						onClick={async (e) => {
							e.stopPropagation();
							update(
								rowIndex,
								part,
								{
									...part,
									croppedImage: await crop(row.layout.type, layout, part.originalImage, part.croppedImage),
									images: [],
									type: PostPartType.Image,
								},
							);
						}}
					>✂</button> : null}
				</div>
			}</Row>

			{row.layout.type === LayoutType.Full ? <label className='border-label'>
				<input
					type='checkbox'
					checked={row.showBorders}
					onChange={(event) => { updateLayout(rowIndex, row.layout.type, {showBorders: event.currentTarget.checked}); }}
				/>{' borders'}
			</label> : null}

			{row.parts.find((part) => part.type === PostPartType.Video) !== undefined ? <>
				<label className='border-label'>
					<input
						type='checkbox'
						checked={row.autoplay}
						onChange={(event) => { updateLayout(rowIndex, row.layout.type, {autoplay: event.currentTarget.checked}); }}
					/>{' autoplay'}
				</label>

				<label className='border-label'>
					<input
						type='checkbox'
						checked={row.playInLoop}
						onChange={(event) => { updateLayout(rowIndex, row.layout.type, {playInLoop: event.currentTarget.checked}); }}
					/>{' loop'}
				</label>
			</> : null}
		</div>)}

		<div
			onDragOver={(e) => { e.preventDefault(); }}
			onDragEnter={() => {
				if (draggedRow !== null) {
					setTargetRow('last');
				}
			}}
			onDrop={(e) => {
				e.preventDefault();
				moveRow();
			}}
		>
			{showAdd.append ? <PostLayoutSelect
				onChange={(type) => {
					setShowAdd({prepend: false, append: false});
					addRow(type);
				}}
			/> : <button className='btn-add-row' onClick={() => { setShowAdd({prepend: false, append: true}); }}>+</button>}
		</div>
	</div>;
};

RowsEditor.displayName = 'PostRowsEditor';
