export interface Image extends Readonly<{
	blob: Blob;
	size: Size;
}> {}

export interface Crop {
	height: number;
	width: number;
	x: number;
	y: number;
}

export class Size {

	public height: number;
	public width: number;

	public constructor(width: number, height: number) {
		this.width = width;
		this.height = height;
	}

	public calculateAspectRatio(): number {
		return this.width / this.height;
	}

}

export const resolveImageFileExtension = (type: string): string | null => {
	switch (type) {
		case 'image/jpeg':
			return 'jpg';
		case 'image/png':
			return 'png';
		case 'image/gif':
			return 'gif';
		case 'image/svg+xml':
			return 'svg';
		default:
			return null;
	}
};

export const resizeImage = async (image: HTMLImageElement, type: string, targetWidth: number): Promise<Image> => new Promise((resolve, reject) => {
	const imageSize = new Size(image.width, image.height);
	const aspectRatio = imageSize.calculateAspectRatio();

	let currentWidth = image.width;
	const step = image.width / 10;

	let [currentCanvas, currentContext] = createCanvas(image.width, image.height);
	let [tempCanvas, tempContext] = createCanvas(image.width, image.height);
	currentContext.drawImage(image, 0, 0, currentWidth, currentWidth / aspectRatio);

	for (let nextWidth = currentWidth - step; nextWidth > targetWidth; nextWidth = currentWidth - step) {
		tempContext.drawImage(
			currentCanvas,
			0,
			0,
			currentWidth,
			currentWidth / aspectRatio,
			0,
			0,
			nextWidth,
			nextWidth / aspectRatio,
		);
		currentContext.clearRect(0, 0, currentCanvas.width, currentCanvas.height);

		currentWidth = nextWidth;
		[currentCanvas, currentContext, tempCanvas, tempContext] = [tempCanvas, tempContext, currentCanvas, currentContext];
	}

	const [finalCanvas, finalContext] = createCanvas(targetWidth, targetWidth / aspectRatio);
	finalContext.drawImage(currentCanvas, 0, 0, currentWidth, currentWidth / aspectRatio, 0, 0, finalCanvas.width, finalCanvas.height);
	finalCanvas.toBlob((blob) => {
		if (blob === null) {
			reject(new Error());
			return;
		}
		resolve({blob, size: new Size(finalCanvas.width, finalCanvas.height)});
	}, type, 0.9);
});

const createCanvas = (width: number, height: number): [HTMLCanvasElement, CanvasRenderingContext2D] => {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	if (context === null) {
		throw new Error();
	}

	canvas.width = width;
	canvas.height = height;

	context.imageSmoothingEnabled = true;
	context.imageSmoothingQuality = 'high';

	return [canvas, context];
};

export const cropImage = async (image: HTMLImageElement, type: string, crop: Crop): Promise<Image> => new Promise((resolve, reject) => {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	if (context === null) {
		reject(new Error());
		return;
	}

	canvas.width = crop.width;
	canvas.height = crop.height;

	context.imageSmoothingEnabled = true;
	context.imageSmoothingQuality = 'high';
	context.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);

	canvas.toBlob((blob) => {
		if (blob === null) {
			reject(new Error());
			return;
		}
		resolve({blob, size: new Size(canvas.width, canvas.height)});
	}, type, 1);
});

export const loadImage = async (file: Blob): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => {
		if (typeof reader.result === 'string') {
			resolve(loadImageFromUrl(reader.result));
		} else {
			reject(new Error());
		}
	});
	reader.readAsDataURL(file);
});

export const loadImageFromUrl = async (url: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
	const image = new window.Image();
	image.addEventListener('load', () => { resolve(image); });
	image.addEventListener('error', reject);
	image.crossOrigin = 'anonymous';
	image.src = url;
});
